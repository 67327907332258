
































import Vue, { PropOptions, PropType } from "vue";
import SideMatch from "@/Classes/SideMatch";
import SideMatchOverview from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchOverview.vue";
import MatchExtModel from "@/Classes/MatchExtModel";

export default Vue.extend({
  name: "MatchOverview",
  components: { SideMatchOverview },
  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    sideMatches: {
      type: Array as PropType<SideMatch[]>,
      required: false,
      default() {
        return [];
      },
    } as PropOptions<SideMatch[]>,
  },
});
