<template>
  <v-container class="match-header-skeleton-loader elevation-2 mb-5" fluid>
    <v-row>
      <v-col md="1" cols="2" align-self="start" class="pb-0">
        <v-skeleton-loader type="text" />
      </v-col>
      <v-col
        md="4"
        cols="6"
        offset-md="3"
        offset="1"
        align-self="end"
        class="pb-0"
      >
        <v-skeleton-loader type="text" />
      </v-col>
    </v-row>

    <v-row justify="center">
      <v-col cols="10" md="7">
        <v-skeleton-loader type="text@4" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "MatchHeaderSkeletonLoader",

  computed: {
    /**
     * Returns TRUE if currently in mobile view; otherwise FALSE.
     *
     * @return {boolean}
     */
    isMobile() {
      return this.$vuetify.breakpoint.width < 701;
    },
  },
};
</script>

<style scoped lang="scss">
.match-header-skeleton-loader {
  background-color: #fff;
  padding-bottom: 15px;
  min-height: 125px;
}

.v-skeleton-loader.lg {
  ::v-deep .v-skeleton-loader__text {
    height: 20px;
    margin-bottom: 15px;
  }
}
</style>
