






































































import {
  CompetitionModel,
  MatchModel,
} from "@memberpoint/ba-result-components";
import {
  CompetitionScoringManager,
  MatchesPayload,
} from "@/services/CompetitionScoringManager";
import PullToRefresh from "@/directives/PullToRefresh";
import ScoredMatchesMixin from "@/Mixins/ScoredMatchesMixin";
import Vue, { PropType, VueConstructor } from "vue";
import { ScoringOptions, SelectItem, GroupedMatches } from "@/types";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";
import {
  mdiChevronDown,
  mdiAccountGroupOutline,
  mdiClipboardListOutline,
} from "@mdi/js";
import BottomSheetSelection from "@/components/BottomSheetSelection/index.vue";
import MatchTable from "@/components/MatchTable/index.vue";
import CompetitionBlock from "@/components/CompetitionBlock/index.vue";
import MatchExtModel from "@/Classes/MatchExtModel";
import SavedMatches from "@/services/SavedMatches";

const FINAL_SERIES_GROUP = "__FINALS_SERIES__";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof ScoredMatchesMixin>
>).extend({
  name: "MatchesTab",

  components: { CompetitionBlock, MatchTable, BottomSheetSelection },

  directives: {
    pullToRefresh: PullToRefresh,
  },

  mixins: [ScoredMatchesMixin],

  props: {
    competition: {
      type: [CompetitionModel, String] as PropType<CompetitionModel | string>,
      required: true,
    },
  },

  data() {
    return {
      loadingMatches: false,
      loadingMatchesError: null as string | null,
      sectionGroupedMatches: {} as GroupedMatches,
      scoringOptions: {} as ScoringOptions | undefined,
      loadingCompetition: false,
      loadedCompetition: null as null | CompetitionModel,
      selectedSection: null as string | null,
      dropdownIcon: mdiChevronDown,
      groupIcon: mdiAccountGroupOutline,
      groupedMatchesIcon: mdiClipboardListOutline,
    };
  },

  computed: {
    /**
     * Returns the competition ID from the competition prop.
     *
     * @return {string}
     */
    competitionId(): string {
      if (typeof this.competition === "string") {
        return this.competition;
      }

      return this.competition.id;
    },

    /**
     * Returns the match group options.
     *
     * @return {SelectItem<number>[]}
     */
    matchGroupOptions(): SelectItem<string>[] {
      const options: SelectItem<string>[] = [];

      for (const group in this.sectionGroupedMatches) {
        if (
          Object.prototype.hasOwnProperty.call(
            this.sectionGroupedMatches,
            group
          )
        ) {
          options.push({
            text: this.sectionGroupedMatches[group].groupName || "",
            value: group,
          });
        }
      }

      return options;
    },

    /**
     * Returns the matches grouped by rounds from the matches
     * belonging to the selected section.
     *
     * @return {GroupedMatches}
     */
    matchesGroupedByRound(): GroupedMatches {
      const matches = this.getMatchFromSectionGroup(this.selectedSection || "");

      // Group matches by round.
      const groupedByRounds: GroupedMatches = {};

      matches.forEach((match: MatchModel) => {
        let group, groupName;

        if (match.isFinalsSeries) {
          group = FINAL_SERIES_GROUP;
          groupName = "FINALS SERIES";
        } else {
          group = match.round;
          groupName = "ROUND " + match.round;

          // If the match has the round name then use that as the group name.
          if (
            match instanceof MatchExtModel &&
            typeof match.roundName === "string"
          ) {
            groupName = match.roundName;
          }
        }

        if (!groupedByRounds[group]) {
          groupedByRounds[group] = {
            groupName,
            matches: [],
          };
        }

        groupedByRounds[group].matches.push(match);

        // Update if the match exists in the current user's saved matches.
        SavedMatches.updateMatch(match);
      });

      return groupedByRounds;
    },
  },

  watch: {
    /**
     * Whenever the competition ID changes we should re-fetch
     * the matches
     */
    competitionId: {
      handler(nv) {
        this.loadMatches(nv);

        // If competition provided is an ID then load from server.
        if (typeof this.competition === "string") {
          this.loadCompetition(this.competition);
        } else {
          this.loadedCompetition = this.competition;
        }
      },
      immediate: true,
    },
  },

  methods: {
    /**
     * Returns the matches from the group.
     *
     * @return {MatchModel[]}
     */
    getMatchFromSectionGroup(group: string): MatchModel[] {
      if (this.sectionGroupedMatches[group]) {
        return this.sectionGroupedMatches[group].matches;
      }

      return [];
    },

    /**
     * Refresh the matches. This is a handler for the "pullToRefresh" directive.
     */
    refresh() {
      this.loadMatches(this.competitionId);
    },

    /**
     * Load the competition.
     */
    loadCompetition(competitionId: string) {
      if (this.loadingCompetition) {
        return;
      }

      this.loadingCompetition = true;

      const manager = new CompetitionScoringManager();

      manager
        .getCompetition(competitionId)
        .then((competition: CompetitionModel) => {
          this.loadedCompetition = competition;
        })
        .finally(() => {
          this.loadingCompetition = false;
        });
    },

    /**
     * Load the matches for the competition.
     *
     * @param {string} competitionId
     */
    loadMatches(competitionId: string): void {
      if (this.loadingMatches) {
        return;
      }

      this.loadingMatches = true;
      this.loadingMatchesError = null;

      const manager = new CompetitionScoringManager();

      manager
        .getMatchesForMarker(competitionId)
        .then((matchesPayload: MatchesPayload) => {
          const groupedMatches: GroupedMatches = {};

          // Group matches into sections.
          (matchesPayload.matches || []).forEach((match: MatchModel) => {
            let group = match.pool,
              groupName = "Section " + match.pool;

            // If the match has a pool name then use that as the group name.
            if (
              match instanceof MatchExtModel &&
              typeof match.poolName === "string"
            ) {
              groupName = match.poolName;
            }

            if (!groupedMatches[group]) {
              groupedMatches[group] = {
                groupName,
                matches: [],
              };
            }

            groupedMatches[group].matches.push(match);
          });

          // Select the first section.
          const groups = Object.keys(groupedMatches);
          if (groups.length > 0) {
            this.selectedSection = groups[0];
          }

          this.sectionGroupedMatches = groupedMatches;

          if (
            Object.prototype.hasOwnProperty.call(
              matchesPayload,
              "scoringOptions"
            )
          ) {
            this.scoringOptions = matchesPayload.scoringOptions;
          }

          // Sync the local stored matches.
          this.updateScoredMatches(matchesPayload.matches);
        })
        .catch((response) => {
          this.loadingMatchesError = ApiResponseHelper.getErrorMessageFromResponse(
            response
          );
        })
        .finally(() => {
          this.loadingMatches = false;
        });
    },

    /**
     * Handle the "match-selected" event from the MatchTable component.
     *
     * @param {MatchModel} match
     */
    onMatchSelected(match: MatchModel): void {
      this.$emit("match-selected", match, this.scoringOptions);
    },
  },
});
