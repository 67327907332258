



























import {
  CompetitionModel,
  DateFormatter,
} from "@memberpoint/ba-result-components";
import Icon from "@/assets/Icons";
import Vue from "vue";
import LogoAvatar from "@/components/LogoAvatar/index.vue";

export default Vue.extend({
  name: "CompetitionBlock",
  components: { LogoAvatar },
  props: {
    /**
     * @type {CompetitionModel} The competition to display
     */
    competition: {
      type: CompetitionModel,
      required: true,
    },
    static: {
      type: Boolean,
      required: false,
      default: false,
    },
    noBackground: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      chevronIcon: Icon.RIGHT_ARROW,
    };
  },
  computed: {
    ownerUrl(): string {
      return "";

      // return "https://wos-bowls-cdn-dev.s3.ap-southeast-2.amazonaws.com/asset_23dba3d4-2f96-45fa-b730-fcd36311a991.jpeg";
    },

    /**
     * Generate a date range in a standardized format
     */
    formattedDate(): string {
      if (typeof this.competition.startDateUTC !== "number") {
        return "";
      }

      if (typeof this.competition.endDateUTC === "number") {
        return DateFormatter.formatDateRange(
          this.competition.startDateUTC,
          this.competition.endDateUTC
        );
      }

      return DateFormatter.formatDate(this.competition.startDateUTC);
    },
  },

  methods: {
    /**
     * Fired when the competition block is clicked, emit an event called click
     */
    onClick() {
      if (!this.static) {
        this.$emit("click");
      }
    },
  },
});
