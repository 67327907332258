




























































































































import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import {
  CompetitorPlayerModel,
  EndModel,
} from "@memberpoint/ba-result-components";
import { ScoringOptions, SelectItem } from "@/types";
import SideMatch from "@/Classes/SideMatch";
import MatchOverview from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/MatchOverview.vue";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import SideMatchStatusAlert from "@/controllers/app/marker-matches/scorer/_internal/ResultScorer/SideShot/SideMatchStatusAlert.vue";
import MatchStateAlert from "@/components/MatchStateAlert/index.vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import MatchExtModel from "@/Classes/MatchExtModel";
import { mdiAccountGroupOutline, mdiCheckCircle } from "@mdi/js";
import BottomSheetSelection from "@/components/BottomSheetSelection/index.vue";
import MatchBlock from "@/components/MatchBlock/index.vue";
import LoadSideMatchesMixin from "@/controllers/app/marker-matches/scorer/Mixins/LoadSideMatchesMixin";

interface SideMatchOption extends SelectItem<string> {
  isSideMatch: boolean;
  isFinalized?: boolean;
}

const MATCH_OVERVIEW_OPTION = "--match-overview--";

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchDetailsMixin> &
    InstanceType<typeof LoadSideMatchesMixin>
>).extend({
  name: "SideShotResultOverview",

  components: {
    MatchBlock,
    BottomSheetSelection,
    MatchStateAlert,
    SideMatchStatusAlert,
    MatchOverview,
  },

  mixins: [MatchDetailsMixin, LoadSideMatchesMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,
  },

  data() {
    return {
      matchData: this.match,
      selected: MATCH_OVERVIEW_OPTION as string | null,
      ends: [] as EndModel[],
      loadingEnds: false,
      loadingEndsErrors: [] as string[],
      groupIcon: mdiAccountGroupOutline,
      checkIcon: mdiCheckCircle,
      sideMatches: [] as SideMatch[],
    };
  },

  computed: {
    /**
     * Returns TRUE if currently in mobile view; otherwise FALSE.
     *
     * @return {boolean}
     */
    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    /**
     * Returns the options for the side match selection.
     *
     * @return {SideMatchOption[]}
     */
    sideMatchOptions(): SideMatchOption[] {
      const options: SideMatchOption[] = [
        {
          text: "View Match Overview",
          value: MATCH_OVERVIEW_OPTION,
          isSideMatch: false,
          isFinalized: false,
        },
      ];

      this.sideMatches.forEach((sideMatch: SideMatch, index) => {
        let text = sideMatch.format.name;

        if (sideMatch.format.specialisation) {
          text += ` (${sideMatch.format.specialisation})`;
        }

        options.push({
          text: `Rink ${index + 1} - ${text}`,
          value: sideMatch.id,
          isSideMatch: true,
          isFinalized: sideMatch.isFinalized,
        });
      });

      return options;
    },

    /**
     * Returns TRUE if the "Match Overview" option is currently selected; otherwise FALSE.
     *
     * @return {boolean}
     */
    isMatchOverviewOptionSelected(): boolean {
      return this.selected === MATCH_OVERVIEW_OPTION;
    },

    /**
     * Returns the ID of the side match that is currently selected.
     *
     * @return {string|null}
     */
    selectedSideMatchID(): string | null {
      if (
        typeof this.selected === "string" &&
        this.selected !== MATCH_OVERVIEW_OPTION
      ) {
        return this.selected;
      }

      return null;
    },

    /**
     * Returns the current selected side match.
     *
     * @return {SideMatch|null}
     */
    selectedSideMatch(): SideMatch | null {
      if (this.selectedSideMatchID === null) {
        return null;
      }

      return this.getSideMatchByID(this.selectedSideMatchID);
    },

    /**
     * Returns the players for competitor 1 from the selected side match.
     *
     * @return {CompetitorPlayerModel[]}
     */
    selectedSideMatchCompetitor1Players(): CompetitorPlayerModel[] {
      if (this.selectedSideMatch === null || !this.selectedSideMatch.teamOne) {
        return [];
      }

      return this._createPlayersFromTeam(this.selectedSideMatch.teamOne);
    },

    /**
     * Returns the players for competitor 2 from the selected side match.
     *
     * @return {CompetitorPlayerModel[]}
     */
    selectedSideMatchCompetitor2Players(): CompetitorPlayerModel[] {
      if (this.selectedSideMatch === null || !this.selectedSideMatch.teamTwo) {
        return [];
      }

      return this._createPlayersFromTeam(this.selectedSideMatch.teamTwo);
    },
  },

  watch: {
    /**
     * Watch for changes in side match selection.
     *
     * @param {SideMatch|null} newValue
     */
    selectedSideMatch(newValue: SideMatch | null) {
      if (newValue !== null) {
        this.loadEndsForSideMatch(newValue);
      } else {
        this.ends = [];
      }

      this.$emit("side-match-selected", newValue);
    },
  },

  created() {
    this.loadSideMatchesForMatch(this.match).then((sideMatches) => {
      this.sideMatches = sideMatches;
    });
  },

  methods: {
    /**
     * Returns the side match matching the given ID or NULL if there is none selected.
     *
     * @return {SideMatch|null}
     */
    getSideMatchByID(id: string): SideMatch | null {
      const index = this.sideMatches.findIndex(
        (sideMatch) => sideMatch.id === id
      );

      if (index === -1) {
        return null;
      }

      return this.sideMatches[index] || null;
    },

    /**
     * Load the ends for the side match.
     *
     * @param {SideMatch} sideMatch
     */
    loadEndsForSideMatch(sideMatch: SideMatch): void {
      this.loadingEnds = true;
      this.loadingEndsErrors = [];

      const markerManager = new CompetitionScoringManager();

      markerManager
        .getEndsForOwner(sideMatch.handle)
        .then((ends: EndModel[]) => {
          this.ends = ends;
        })
        .catch((error) => {
          this.loadingEndsErrors = CompetitionScoringManager.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.loadingEnds = false;
        });
    },
  },
});
