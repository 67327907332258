import TeamPlayer from "@/Classes/TeamPlayer";

export default class Team {
  protected _teamPlayers: Array<TeamPlayer> = [];

  constructor(teamPlayers: Array<TeamPlayer> = []) {
    this._teamPlayers = teamPlayers;
  }

  /**
   * Returns an array of team players.
   *
   * @return {Array<TeamPlayer>}
   */
  get teamPlayers(): Array<TeamPlayer> {
    return this._teamPlayers;
  }
}
