





















































import Vue from "vue";
import SideMatch from "@/Classes/SideMatch";
import { CompetitorModel } from "@memberpoint/ba-result-components";
import MatchExtModel from "@/Classes/MatchExtModel";

export default Vue.extend({
  name: "SideMatchStatusAlert",

  props: {
    sideMatch: {
      type: SideMatch,
      required: true,
    },

    match: {
      type: MatchExtModel,
      required: true,
    },
  },

  computed: {
    winnerCompetitor(): CompetitorModel | null {
      if (this.sideMatch.winnerCompetitorID !== null) {
        const winnerCompetitorID = this.sideMatch.winnerCompetitorID;

        if (winnerCompetitorID === this.match.competitorOne.id) {
          return this.match.competitorOne;
        } else if (winnerCompetitorID === this.match.competitorTwo.id) {
          return this.match.competitorTwo;
        }
      }

      return null;
    },

    loserCompetitor(): CompetitorModel | null {
      if (this.sideMatch.winnerCompetitorID !== null) {
        const winnerCompetitorID = this.sideMatch.winnerCompetitorID;

        if (winnerCompetitorID === this.match.competitorOne.id) {
          return this.match.competitorTwo;
        } else if (winnerCompetitorID === this.match.competitorTwo.id) {
          return this.match.competitorOne;
        }
      }

      return null;
    },
  },
});
