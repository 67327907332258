var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"side-shot-result-overview"},[_c('match-block',{staticClass:"match match-header pt-3 px-3 cloud",attrs:{"match":_vm.matchData,"favourite-toggle":"","hide-icon":"","display-context":""}}),_c('bottom-sheet-selection',{attrs:{"items":_vm.sideMatchOptions,"loading":_vm.loadingSideMatches,"placeholder":"SELECT RINK OR OVERVIEW"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [(item.isSideMatch)?_c('span',[_c('strong',{staticClass:"text-uppercase vertical-middle"},[_vm._v(_vm._s(item.text))]),(item.isFinalized)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"success"}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()],1):_c('span',[_c('strong',{staticClass:"text-uppercase"},[_vm._v(_vm._s(item.text))])])]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.groupIcon))]),_c('span',{staticClass:"font-weight-medium"},[(item.isSideMatch)?[_c('strong',{staticClass:"vertical-middle"},[_vm._v(_vm._s(item.text))]),(item.isFinalized)?_c('v-icon',{staticClass:"ml-1",attrs:{"color":"success","small":""}},[_vm._v(" "+_vm._s(_vm.checkIcon)+" ")]):_vm._e()]:[_vm._v(_vm._s(item.text))]],2)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('div',{staticClass:"match-wrapper"},[(_vm.isMatchOverviewOptionSelected)?[_c('match-state-alert',{attrs:{"match":_vm.match}}),(!_vm.isFinalized && _vm.isConfirmedByHomeTeam)?[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(true),expression:"true"}],staticClass:"text-center",attrs:{"icon":false,"type":"info","prominent":"","text":"","transition":"slide-y-transition"}},[_vm._v(" This match has been confirmed by "),_c('strong',[_vm._v(_vm._s(_vm.matchData.competitorOne.getName()))]),_vm._v(" and is waiting approval. ")])]:_vm._e(),(_vm.sideMatches.length > 0)?_c('v-container',{staticClass:"mt-5"},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"md":"8","sm":"12"}},[_c('match-overview',{attrs:{"side-matches":_vm.sideMatches,"match":_vm.match}})],1)],1)],1):_vm._e()]:(_vm.selectedSideMatch)?[_c('side-match-status-alert',{attrs:{"side-match":_vm.selectedSideMatch,"match":_vm.match}}),(!_vm.loadingEnds)?_c('div',{staticClass:"end-block mb-2 mt-5"},[_c('v-container',{staticClass:"py-0"},[_c('bar-scorecard',{attrs:{"competitor-one":_vm.match.competitorOne,"competitor-two":_vm.match.competitorTwo,"competitor-one-players":_vm.selectedSideMatchCompetitor1Players.length > 0
                ? _vm.selectedSideMatchCompetitor1Players
                : _vm.createEmptyTeamPlayersForSelectedSideMatch(
                    _vm.selectedSideMatch
                  ),"competitor-two-players":_vm.selectedSideMatchCompetitor2Players.length > 0
                ? _vm.selectedSideMatchCompetitor2Players
                : _vm.createEmptyTeamPlayersForSelectedSideMatch(
                    _vm.selectedSideMatch
                  ),"ends":_vm.ends,"format":_vm.selectedSideMatch.format.canonicalId,"show-competitors":""}})],1)],1):_c('v-container',{staticClass:"d-flex flex-grow-1 justify-center align-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":50,"color":"green"}})],1)]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }