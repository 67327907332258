import Vue from "vue";
import SetResultManager from "@/services/SetResultManager";
import { MatchModel } from "@memberpoint/ba-result-components";
import MatchSet from "@/Classes/MatchSet";

export default Vue.extend({
  data() {
    return {
      loadingMatchSets: false,
    };
  },

  methods: {
    /**
     * Load the sets for the match.
     *
     * @param {MatchModel} match
     * @param {boolean} initializeSet
     * @return {Promise<MatchSet[]>}
     */
    loadSetsForMatch(
      match: MatchModel,
      initializeSet = false
    ): Promise<MatchSet[]> {
      if (this.loadingMatchSets) {
        return Promise.reject("ALREADY_LOADING");
      }

      this.loadingMatchSets = true;

      const setResultManager = new SetResultManager();

      return setResultManager
        .getSetsForMatch(match.id, initializeSet)
        .then((matchSets) => {
          return Promise.resolve(matchSets);
        })
        .finally(() => {
          this.loadingMatchSets = false;
        });
    },
  },
});
