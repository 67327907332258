import Configuration from "@/lib/Configuration";
import http from "@/lib/AppHttpResource";
import SideMatch from "@/Classes/SideMatch";
import { LooseObject } from "@/types";
import Format from "@/Classes/Format";
import Team from "@/Classes/Team";
import TeamPlayer from "@/Classes/TeamPlayer";

export default class SideShotResultManager {
  /**
   * Get the side matches for the match.
   *
   * @param {string} matchID
   * @return Promise<SideMatch[]>
   */
  getSideMatches(matchID: string): Promise<SideMatch[]> {
    return new Promise<SideMatch[]>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/side-matches`;

      http
        .get(url)
        .then((response) => {
          const sideMatchesData = response.data.data || [];

          const sideMatches = sideMatchesData.map((row: LooseObject) => {
            return this._createSideMatchData(
              row.id as string,
              row.attributes as LooseObject
            );
          });

          resolve(sideMatches);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Enter the final scores for the side match.
   *
   * @param {string} matchID
   * @param {string} formatID
   * @param {number} competitorOneScore
   * @param {number} competitorTwoScore
   * @param {boolean} finalize
   * @return {Promise<void>}
   */
  enterFinalScoresForSideMatch(
    matchID: string,
    formatID: string,
    competitorOneScore: number,
    competitorTwoScore: number,
    finalize = false
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/side-matches/${formatID}/final-scores`;

      http
        .put(url, {
          attributes: { competitorOneScore, competitorTwoScore, finalize },
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Finalize the side match.
   *
   * @param {string} matchID
   * @param {string} formatID
   */
  finalizeSideMatch(matchID: string, formatID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/side-matches/${formatID}/finalize`;

      http
        .put(url)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Set the side match as UNPLAYED.
   *
   * @param {string} matchID
   * @param {string} formatID
   */
  setSideMatchAsUnplayed(matchID: string, formatID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/side-matches/${formatID}/unplayed`;

      http
        .put(url)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Forfeit a side match by given competitor.
   *
   * @param {string} matchID
   * @param {string} formatID
   * @param {string} competitorID
   */
  forfeitSideMatch(
    matchID: string,
    formatID: string,
    competitorID: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/side-matches/${formatID}/forfeit`;

      http
        .put(url, { attributes: { competitor: competitorID } })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Returns an instance of the side match from the given attributes.
   *
   * @param {string} id
   * @param {LooseObject} attributes
   */
  _createSideMatchData(id: string, attributes: LooseObject): SideMatch {
    const format = new Format(
      attributes.formatId as string,
      attributes.formatName as string,
      attributes.formatSpecialisation as string,
      attributes.formatCanonicalId as string | null
    );

    let teamOne = null;

    if (Array.isArray(attributes.teamOne)) {
      const teamPlayers = (attributes.teamOne as Array<LooseObject>).map(
        (teamPlayerData) => {
          return new TeamPlayer(
            teamPlayerData.id as string,
            teamPlayerData.name as string | null,
            teamPlayerData.positionId as string | null,
            teamPlayerData.positionName as string | null
          );
        }
      );

      teamOne = new Team(teamPlayers);
    }

    let teamTwo = null;

    if (Array.isArray(attributes.teamOne)) {
      const teamPlayers = (attributes.teamTwo as Array<LooseObject>).map(
        (teamPlayerData) => {
          return new TeamPlayer(
            teamPlayerData.id as string,
            teamPlayerData.name as string | null,
            teamPlayerData.positionId as string | null,
            teamPlayerData.positionName as string | null
          );
        }
      );

      teamTwo = new Team(teamPlayers);
    }

    return new SideMatch(
      id,
      attributes.handle as string,
      format,
      attributes.matchId as string,
      attributes.status as string,
      attributes.isFinalized as boolean,
      attributes.competitorOneShots as number,
      attributes.competitorTwoShots as number,
      teamOne,
      teamTwo,
      attributes.winnerCompetitorId as string | null
    );
  }
}
