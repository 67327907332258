

















































































import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import MatchExtModel from "@/Classes/MatchExtModel";
import { ScoringOptions, SelectItem } from "@/types";
import MatchBlock from "@/components/MatchBlock/index.vue";
import LoadSetMatchesMixin from "@/controllers/app/marker-matches/scorer/Mixins/LoadSetMatchesMixin";
import MatchSet from "@/Classes/MatchSet";
import BottomSheetSelection from "@/components/BottomSheetSelection/index.vue";
import { mdiAccountGroupOutline } from "@mdi/js";
import MatchSetOverview from "@/controllers/app/marker-matches/scorer/MatchSetOverview.vue";
import { EndModel } from "@memberpoint/ba-result-components";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import MatchStateAlert from "@/components/MatchStateAlert/index.vue";

const MATCH_OVERVIEW_OPTION = "--match-overview--";

export default (Vue as VueConstructor<
  Vue &
    InstanceType<typeof MatchDetailsMixin> &
    InstanceType<typeof LoadSetMatchesMixin>
>).extend({
  name: "SetResultOverview",

  components: {
    MatchStateAlert,
    MatchSetOverview,
    BottomSheetSelection,
    MatchBlock,
  },

  mixins: [MatchDetailsMixin, LoadSetMatchesMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,
  },

  data() {
    return {
      matchData: this.match,
      matchSets: [] as MatchSet[],
      selected: MATCH_OVERVIEW_OPTION as string | null,
      groupIcon: mdiAccountGroupOutline,
      matchSetsLoaded: false,
      ends: [] as EndModel[],
      loadingEnds: false,
      loadingEndsErrors: [] as string[],
    };
  },

  computed: {
    /**
     * Returns the match set options for the select dropdown.
     *
     * @return {SelectItem<string>[]}
     */
    matchSetSelectOptions(): SelectItem<string>[] {
      const options: SelectItem<string>[] = [
        {
          text: "View Match Overview",
          value: MATCH_OVERVIEW_OPTION,
        },
      ];

      this.matchSets.forEach((matchSet: MatchSet) => {
        options.push({
          text: `Set ${matchSet.setNumber}`,
          value: matchSet.id,
        });
      });

      return options;
    },

    /**
     * Returns TRUE if the "Match Overview" option is currently selected; otherwise FALSE.
     *
     * @return {boolean}
     */
    isMatchOverviewOptionSelected(): boolean {
      return this.selected === MATCH_OVERVIEW_OPTION;
    },

    /**
     * Returns the ID of the set that is currently selected.
     *
     * @return {string|null}
     */
    selectedMatchSetID(): string | null {
      if (
        typeof this.selected === "string" &&
        this.selected !== MATCH_OVERVIEW_OPTION
      ) {
        return this.selected;
      }

      return null;
    },

    /**
     * Returns the current selected set.
     *
     * @return {MatchSet|null}
     */
    selectedMatchSet(): MatchSet | null {
      if (this.selectedMatchSetID === null) {
        return null;
      }

      return this.getMatchSetByID(this.selectedMatchSetID);
    },
  },

  watch: {
    /**
     * Watch for changes in match set selection.
     *
     * @param {MatchSet|null} newValue
     */
    selectedMatchSet(newValue: MatchSet | null) {
      if (newValue !== null) {
        this.loadEndsForMatchSet(newValue);
      } else {
        this.ends = [];
      }

      this.$emit("match-set-selected", newValue);
    },
  },

  created() {
    this.loadSetsForMatch(this.match)
      .then((matchSets) => {
        this.matchSets = matchSets;
      })
      .finally(() => {
        this.matchSetsLoaded = true;
      });
  },

  methods: {
    /**
     * Returns the match set matching the given ID or NULL if there is none selected.
     *
     * @return {MatchSet|null}
     */
    getMatchSetByID(id: string): MatchSet | null {
      const index = this.matchSets.findIndex((matchSet) => matchSet.id === id);

      if (index === -1) {
        return null;
      }

      return this.matchSets[index] || null;
    },

    /**
     * Load the ends for the match set.
     *
     * @param {MatchSet} matchSet
     */
    loadEndsForMatchSet(matchSet: MatchSet): void {
      this.loadingEnds = true;
      this.loadingEndsErrors = [];

      const markerManager = new CompetitionScoringManager();

      markerManager
        .getEndsForOwner(matchSet.handle)
        .then((ends: EndModel[]) => {
          this.ends = ends;
        })
        .catch((error) => {
          this.loadingEndsErrors = CompetitionScoringManager.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.loadingEnds = false;
        });
    },
  },
});
