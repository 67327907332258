import Vue from "vue";
import { CompetitorModel } from "@memberpoint/ba-result-components";
import MatchResult from "@/Classes/MatchResult";
import MatchExtModel from "@/Classes/MatchExtModel";
import {
  isCompetitorContextForMatch,
  isMarkerContextForMatch,
} from "@/services/ScoringContexts";

export interface Competitor {
  id: string;
  name: string;
  shots: number | null;
  points: number | null;
}

export default Vue.extend({
  data() {
    return {
      matchData: null as MatchExtModel | null,
    };
  },

  computed: {
    /**
     * Returns TRUE if there are competitors that have not been determined;
     * otherwise FALSE.
     *
     * @return {boolean}
     */
    hasTBDCompetitors(): boolean {
      if (this.matchData === null) {
        return false;
      }

      if (
        !(this.matchData.competitorOne instanceof CompetitorModel) ||
        !(this.matchData.competitorTwo instanceof CompetitorModel)
      ) {
        return true;
      }

      if (
        !this.matchData.competitorOne.isBye() &&
        this.matchData.competitorOne.isTbc()
      ) {
        return true;
      }

      return (
        !this.matchData.competitorTwo.isBye() &&
        this.matchData.competitorTwo.isTbc()
      );
    },

    /**
     * Returns the result instance from the match.
     *
     * @return {MatchResult|null}
     */
    result(): MatchResult | null {
      if (this.matchData === null) {
        return null;
      }

      return this.matchData.result instanceof MatchResult
        ? this.matchData.result
        : null;
    },

    /**
     * Returns TRUE if the match has been finalized; otherwise FALSE.
     *
     * @return {boolean}
     */
    isFinalized(): boolean {
      if (this.result !== null) {
        return this.result.isFinalized;
      }

      return false;
    },

    /**
     * Returns the status of the match result.
     *
     * @return {string|null}
     */
    status(): string | null {
      if (this.result !== null) {
        return this.result.status;
      }

      return null;
    },

    /**
     * Returns TRUE if the match is a draw; otherwise FALSE.
     *
     * @return {boolean}
     */
    isDraw(): boolean {
      return this.result !== null && this.result.status === "draw";
    },

    /**
     * Returns the competitor that is the winner.
     *
     * @return {Competitor|null}
     */
    winnerCompetitor(): Competitor | null {
      if (this.matchData && this.result !== null) {
        if (this.result.wonBy === 1) {
          return {
            id: this.matchData.competitorOne.id,
            name: this.matchData.competitorOne.getName(),
            shots: this.result.competitorOneShots,
            points: this.result.competitorOnePoints,
          };
        } else if (this.result.wonBy === 2) {
          return {
            id: this.matchData.competitorTwo.id,
            name: this.matchData.competitorTwo.getName(),
            shots: this.result.competitorTwoShots,
            points: this.result.competitorTwoPoints,
          };
        }
      }

      return null;
    },

    /**
     * Returns the competitor that is a loser.
     *
     * @return {Competitor|null}
     */
    loserCompetitor(): Competitor | null {
      if (this.matchData && this.result !== null) {
        if (this.result.wonBy === 1) {
          return {
            id: this.matchData.competitorTwo.id,
            name: this.matchData.competitorTwo.getName(),
            shots: this.result.competitorTwoShots,
            points: this.result.competitorTwoPoints,
          };
        } else if (this.result.wonBy === 2) {
          return {
            id: this.matchData.competitorOne.id,
            name: this.matchData.competitorOne.getName(),
            shots: this.result.competitorOneShots,
            points: this.result.competitorOnePoints,
          };
        }
      }

      return null;
    },

    /**
     * Returns TRUE if the match has scores; otherwise FALSE.
     *
     * @return {boolean}
     */
    matchHasScores(): boolean {
      if (this.matchData && this.matchData.result instanceof MatchResult) {
        return (
          this.matchData.result.competitorOneShots !== null ||
          this.matchData.result.competitorTwoShots !== null
        );
      }

      return false;
    },

    /**
     * Returns TRUE if the match is a BYE; otherwise FALSE.
     *
     * @return {boolean}
     */
    isBye(): boolean {
      if (this.matchData === null) {
        return false;
      }

      if (
        this.matchData.competitorOne instanceof CompetitorModel &&
        this.matchData.competitorOne.isBye()
      ) {
        return true;
      }

      return (
        this.matchData.competitorTwo instanceof CompetitorModel &&
        this.matchData.competitorTwo.isBye()
      );
    },

    /**
     * Returns TRUE if current user is in a competitor context for the match; otherwise FALSE.
     * If a user has a "competitor" context even though the current user the "marker" context,
     * the user is still in a competitor context.
     *
     * @return {boolean}
     */
    isCompetitorContext(): boolean {
      if (this.matchData === null) {
        return false;
      }

      return isCompetitorContextForMatch(this.matchData);
    },

    /**
     * Returns TRUE if current user is in a marker context for the match; otherwise FALSE.
     * A marker context is when a user has a "marker" context and no "competitor" context.
     *
     * @return {boolean}
     */
    isMarkerContext(): boolean {
      if (this.matchData === null) {
        return false;
      }

      return isMarkerContextForMatch(this.matchData);
    },

    /**
     * Returns TRUE if the match has been confirmed by the home team; otherwise FALSE.
     *
     * @return {boolean}
     */
    isConfirmedByHomeTeam(): boolean {
      return this.result instanceof MatchResult && this.result.hasConfirmation;
    },
  },
});
