






















































import MatchTab from "./tabs/MatchTab.vue";
import TitleBar from "@/components/TitleBar/index.vue";
import {
  CompetitionModel,
  MatchModel,
} from "@memberpoint/ba-result-components";
import Vue from "vue";
import CompetitionsTab from "@/controllers/app/marker-match-finder/tabs/CompetitionsTab.vue";
import MatchesTab from "@/controllers/app/marker-match-finder/tabs/MatchesTab.vue";
import { TouchWrapper } from "vuetify";
import { NavigationGuardNext, Route } from "vue-router";
import { ScoringOptions } from "@/types";
import MatchExtModel from "@/Classes/MatchExtModel";
import SavedMatches from "@/services/SavedMatches";

interface Tab {
  name: string;
  title: string;
  disabled: boolean;
}

export default Vue.extend({
  name: "AppMatchFinderController",

  components: {
    MatchesTab,
    CompetitionsTab,
    TitleBar,
    MatchTab,
  },

  data() {
    return {
      currentTab: "competitions",
      currentCompetition: null as null | string | CompetitionModel,
      currentMatch: null as null | string | MatchExtModel,
      currentMatchScoringOptions: {},
      tabsKey: 0,
      popStateHandled: false,
      bypassRouteLeaveGuard: false,
    };
  },

  computed: {
    /**
     * Returns an array of tab declarations.
     *
     * @return {Array<Tab>}
     */
    tabs(): Array<Tab> {
      return [
        {
          name: "competitions",
          title: "Competitions",
          disabled: false,
        },
        {
          name: "matches",
          title: "Matches",
          disabled: this.currentCompetition === null,
        },
        {
          name: "match",
          title: "Scorecard",
          disabled: this.currentMatch === null,
        },
      ];
    },
  },

  watch: {
    $route: {
      handler(newValue) {
        // When there are no competition or match filter in the current route query
        // then go back to the "competitions" tab.
        if (
          !Object.prototype.hasOwnProperty.call(newValue.query, "c") &&
          !Object.prototype.hasOwnProperty.call(newValue.query, "m")
        ) {
          this.goToCompetitionPage();
        }
      },
    },
  },

  created() {
    window.addEventListener("popstate", this.popStateHandler);

    if (Object.prototype.hasOwnProperty.call(this.$route.query, "c")) {
      const competitionId = this.$route.query.c as string;

      if (competitionId.trim().length > 0) {
        this.currentCompetition = competitionId;

        // Auto select the tab.
        this.currentTab = "matches";
      }
    }

    if (Object.prototype.hasOwnProperty.call(this.$route.query, "m")) {
      const matchId = this.$route.query.m as string;

      if (matchId.trim().length > 0) {
        this.currentMatch = matchId;

        // Auto select the tab.
        this.currentTab = "match";
      }
    }
  },

  destroyed() {
    window.removeEventListener("popstate", this.popStateHandler);
  },

  /**
   * Handle when about to leave to a different route.
   */
  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    // This to allow the "popstate" event to trigger before this guard.
    setTimeout(() => {
      if (this.popStateHandled && !this.bypassRouteLeaveGuard) {
        // We want to prevent user from going back to another page and
        // instead only switch to the previous tab if there is one
        // otherwise we go back as normal.
        const prevTab = this.getPreviousTab();

        if (prevTab) {
          this.currentTab = prevTab.name;

          // Prevent to next route.
          next(false);
          return;
        }
      }

      next();
    }, 100);
  },

  methods: {
    popStateHandler() {
      this.popStateHandled = true;
    },

    /**
     * Returns the next tab or NULL if not found.
     *
     * @return {Tab|null}
     */
    getNextTab(): Tab | null {
      if (!this.currentTab) {
        return null;
      }

      const currentTabIndex = this.getTabIndexByName(this.currentTab);

      if (currentTabIndex !== -1 && currentTabIndex < this.tabs.length - 1) {
        return this.tabs[currentTabIndex + 1];
      }

      return null;
    },

    /**
     * Returns the previous tab or NULL if not found.
     *
     * @return {Tab|null}
     */
    getPreviousTab(): Tab | null {
      if (!this.currentTab) {
        return null;
      }

      const currentTabIndex = this.getTabIndexByName(this.currentTab);

      if (currentTabIndex > 0) {
        return this.tabs[currentTabIndex - 1];
      }

      return null;
    },

    /**
     * Return the tab index by name or -1 if not found.
     *
     * @return {number}
     */
    getTabIndexByName(name: string): number {
      return this.tabs.findIndex((tab) => tab.name === name);
    },

    refresh() {
      this.tabsKey++;
    },

    /**
     * Handle the "competition-selected" event from the CompetitionsTab component.
     *
     * @param {CompetitionModel} competition
     */
    onCompetitionSelected(competition: CompetitionModel): void {
      this.currentCompetition = competition;

      // Add the competition to route.
      const currentComp = this.$route.query.c;

      if (!currentComp || currentComp !== competition.id) {
        this.$router.replace({
          query: { c: competition.id },
        });
      }

      this.goToMatchesPage();
    },

    /**
     * Handle the "match-selected" event from the MatchesTab component.
     *
     * @param {MatchModel} match
     * @param {ScoringOptions} scoringOptions
     */
    onMatchSelected(match: MatchModel, scoringOptions: ScoringOptions): void {
      // Store ID instead of match object so that the match tab can re-fetch the match.
      this.currentMatch = match.id;
      this.currentMatchScoringOptions = scoringOptions;

      // Add the match to route.
      const currentMatch = this.$route.query.m;

      if (!currentMatch || currentMatch !== this.currentMatch) {
        this.$router.replace({
          query: Object.assign({}, this.$route.query, {
            m: this.currentMatch,
          }),
        });
      }

      this.goToMatchPage();
    },

    /**
     * Handle the "score-match-click" event from the match tab.
     *
     * @param {MatchExtModel} match
     * @param {LooseObject} params
     */
    onScoreMatchClick(match: MatchExtModel, params = {}) {
      // Add the match to the users saved matches.
      SavedMatches.addMatch(match);

      // set this flag to skip the route guard check.
      this.bypassRouteLeaveGuard = true;

      // "p" query determines the parameters for the different scorer types.
      const p = JSON.stringify(params);

      this.$router.push({
        name: "app-marker-matches-scorer",
        params: { id: match.id },
        query: { p },
      });
    },

    /**
     * Handle the "touch.left" of the tab items.
     *
     * @param touch
     */
    onSwipeLeft(touch: TouchWrapper) {
      if (touch) {
        const nextTab = this.getNextTab();

        if (nextTab && !nextTab.disabled) {
          this.currentTab = nextTab.name;
        }
      }
    },

    /**
     * Handle the "touch.right" of the tab items.
     *
     * @param touch
     */
    onSwipeRight(touch: TouchWrapper) {
      if (touch) {
        const prevTab = this.getPreviousTab();

        if (prevTab && !prevTab.disabled) {
          this.currentTab = prevTab.name;
        }
      }
    },

    goToMatchesPage() {
      this.currentTab = "matches";
    },

    goToCompetitionPage() {
      this.currentTab = "competitions";
    },

    goToMatchPage() {
      this.currentTab = "match";
    },
  },
});
