import Format from "@/Classes/Format";
import Team from "@/Classes/Team";

export default class SideMatch {
  protected _id: string;
  protected _handle: string;
  protected _format: Format;
  protected _matchID: string;
  protected _status: string;
  protected _isFinalized: boolean;
  protected _competitorOneShots: number;
  protected _competitorTwoShots: number;
  protected _teamOne: Team | null;
  protected _teamTwo: Team | null;
  protected _winnerCompetitorID: string | null;

  constructor(
    id: string,
    handle: string,
    format: Format,
    matchID: string,
    status = "pending",
    isFinalized = false,
    competitorOneShots = 0,
    competitorTwoShots = 0,
    teamOne: Team | null = null,
    teamTwo: Team | null = null,
    winnerCompetitorID: string | null = null
  ) {
    this._id = id;
    this._handle = handle;
    this._format = format;
    this._matchID = matchID;
    this._status = status;
    this._isFinalized = isFinalized;
    this._competitorOneShots = competitorOneShots;
    this._competitorTwoShots = competitorTwoShots;
    this._teamOne = teamOne;
    this._teamTwo = teamTwo;
    this._winnerCompetitorID = winnerCompetitorID;
  }

  /**
   * Returns the ID of the side match.
   *
   * @return {string}
   */
  get id(): string {
    return this._id;
  }

  /**
   * Returns the handle of the side match.
   *
   * @return {string}
   */
  get handle(): string {
    return this._handle;
  }

  /**
   * Returns an instance of the format that this side match is for.
   *
   * @return {Format}
   */
  get format(): Format {
    return this._format;
  }

  /**
   * Returns the match ID.
   *
   * @return {string}
   */
  get matchID(): string {
    return this._matchID;
  }

  /**
   * Returns the status of the side match.
   *
   * @return {string}
   */
  get status(): string {
    return this._status;
  }

  /**
   * Returns TRUE if the side match is finalized;
   * otherwise FALSE.
   *
   * @return {boolean}
   */
  get isFinalized(): boolean {
    return this._isFinalized;
  }

  /**
   * Returns the competitor 1 shots for the side match.
   *
   * @return {number}
   */
  get competitorOneShots(): number {
    return this._competitorOneShots;
  }

  /**
   * Returns the competitor 2 shots for the side match.
   *
   * @return {number}
   */
  get competitorTwoShots(): number {
    return this._competitorTwoShots;
  }

  /**
   * Returns the team for competitor 1.
   *
   * @return {Team|null}
   */
  get teamOne(): Team | null {
    return this._teamOne;
  }

  /**
   * Returns the team for competitor 1.
   *
   * @return {Team|null}
   */
  get teamTwo(): Team | null {
    return this._teamTwo;
  }

  /**
   * Returns ID of the competitor that won the side match.
   *
   * @return {string|null}
   */
  get winnerCompetitorID(): string | null {
    return this._winnerCompetitorID;
  }
}
