













































































































import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import Vue, { VueConstructor } from "vue";
import MatchExtModel from "@/Classes/MatchExtModel";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof MatchDetailsMixin>
>).extend({
  name: "MatchStateAlert",

  mixins: [MatchDetailsMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    showScores: {
      type: Boolean,
      required: false,
      default: true,
    },
  },

  created() {
    // Set on next tick to allow alert transitions.
    this.$nextTick(() => {
      this.matchData = this.match;
    });
  },
});
