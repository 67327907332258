













































import Vue from "vue";
import SideMatch from "@/Classes/SideMatch";
import MatchExtModel from "@/Classes/MatchExtModel";

interface Label {
  text: string;
  color: string;
}

export default Vue.extend({
  name: "SideMatchOverview",

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    sideMatch: {
      type: SideMatch,
      required: true,
    },
  },

  computed: {
    competitor1Label(): string {
      if (this.sideMatch.teamOne) {
        const playerAtSkip = this.sideMatch.teamOne.teamPlayers.filter(
          (player) => player.positionId === "skip"
        );

        if (playerAtSkip.length > 0) {
          return playerAtSkip[0].name ?? this.match.competitorOne.name;
        }
      }

      return this.match.competitorOne.name;
    },

    competitor2Label(): string {
      if (this.sideMatch.teamTwo) {
        const playerAtSkip = this.sideMatch.teamTwo.teamPlayers.filter(
          (player) => player.positionId === "skip"
        );

        if (playerAtSkip.length > 0) {
          return playerAtSkip[0].name ?? this.match.competitorTwo.name;
        }
      }

      return this.match.competitorTwo.name;
    },

    /**
     * Returns TRUE if competitor 1 is the winner; otherwise FALSE.
     *
     * @return {boolean}
     */
    isCompetitor1Winner(): boolean {
      return (
        this.sideMatch.winnerCompetitorID !== null &&
        this.sideMatch.winnerCompetitorID === this.match.competitorOne.id
      );
    },

    /**
     * Returns TRUE if competitor 2 is the winner; otherwise FALSE.
     *
     * @return {boolean}
     */
    isCompetitor2Winner(): boolean {
      return (
        this.sideMatch.winnerCompetitorID !== null &&
        this.sideMatch.winnerCompetitorID === this.match.competitorTwo.id
      );
    },

    /**
     * Returns TRUE if the side match is a draw; otherwise FALSE.
     *
     * @return {boolean}
     */
    isDraw(): boolean {
      return (
        this.sideMatch.isFinalized &&
        this.sideMatch.winnerCompetitorID === null &&
        this.sideMatch.status === "draw"
      );
    },

    /**
     * Return the appropriate label for comparison for the rink match.
     *
     * @return {Label}
     */
    prepositionLabel(): Label {
      if (this.sideMatch.status === "forfeit") {
        return { text: "FORFEIT", color: "error" };
      }

      if (this.sideMatch.status === "non-played") {
        return { text: "UNPLAYED", color: "info" };
      }

      if (this.isCompetitor1Winner) {
        return { text: "DEFEATED", color: "success-light" };
      } else if (this.isCompetitor2Winner) {
        return { text: "DEFEATED BY", color: "success-light" };
      } else if (this.isDraw) {
        return { text: "DRAW", color: "success-light" };
      }

      return { text: "VS", color: "success-light" };
    },
  },
});
