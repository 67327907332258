import Vue from "vue";
import MatchExtModel from "@/Classes/MatchExtModel";

export default Vue.extend({
  methods: {
    /**
     * Sync any of the scored matches in local storage using the match payload
     * from server.
     *
     * @param {Array<MatchExtModel>} matches
     */
    updateScoredMatches(matches: Array<MatchExtModel>): void {
      const scoredMatches = this.$store.getters["app/scoredMatches"];
      const scoredMatchesIdMap: { [key: string]: number } = {};

      for (let i = 0; i < scoredMatches.length; i++) {
        scoredMatchesIdMap[scoredMatches[i].id] = 1;
      }

      // Update the local scored match state to match that of the one fetched from server.
      matches.forEach((match: MatchExtModel) => {
        if (match.id in scoredMatchesIdMap) {
          this.$store.commit("app/updateScoredMatch", match);
        }
      });
    },
  },
});
