



























































import Vue, { PropOptions, PropType } from "vue";
import MatchExtModel from "@/Classes/MatchExtModel";
import MatchSet from "@/Classes/MatchSet";

export default Vue.extend({
  name: "MatchSetOverview",

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    matchSets: {
      type: Array as PropType<MatchSet[]>,
      required: false,
      default() {
        return [];
      },
    } as PropOptions<MatchSet[]>,
  },

  computed: {
    /**
     * Returns the max number of sets.
     *
     * @return {number}
     */
    maxNumberOfSets(): number {
      const options = this.match.resultOptions;

      // Check if it is in the result options, otherwise just
      // return the current number of match sets.
      if (
        typeof options.maxNumberOfSets === "number" &&
        options.maxNumberOfSets > 0
      ) {
        return options.maxNumberOfSets;
      }

      return this.matchSets.length;
    },

    /**
     * Returns an array of set numbers which should just be an array
     * of numbers in numerical order based on the max number of sets
     * i.e. 1, 2, 3, 4, ...
     *
     * @return {number[]}
     */
    setNumbers(): number[] {
      const setNumbers = [];

      for (let i = 0; i < this.maxNumberOfSets; i++) {
        setNumbers.push(i + 1);
      }

      return setNumbers;
    },
  },

  methods: {
    /**
     * Returns the match set by the set number.
     *
     * @param {number} setNumber
     * @return {?MatchSet}
     */
    getMatchSetBySetNumber(setNumber: number): MatchSet | null {
      const index = this.matchSets.findIndex(
        (matchSet) => matchSet.setNumber === setNumber
      );

      return index !== -1 ? this.matchSets[index] : null;
    },

    /**
     * Returns the template string to render for the competitor shot
     * at the given set number.
     *
     * @param {number} setNumber
     * @param {boolean} forCompetitor1
     * @return {string}
     */
    getCompetitorShotTemplateAtSetNumber(
      setNumber: number,
      forCompetitor1 = true
    ): string {
      const matchSet = this.getMatchSetBySetNumber(setNumber);

      if (matchSet === null) {
        return "";
      }

      const competitor1Shots = matchSet.competitorOneShots;
      const competitor2Shots = matchSet.competitorTwoShots;

      if (forCompetitor1) {
        if (competitor1Shots === null) {
          return "—";
        }

        return competitor1Shots > (competitor2Shots || 0)
          ? `<span class="winner">${competitor1Shots}</span>`
          : `<span>${competitor1Shots}</span>`;
      } else {
        if (competitor2Shots === null) {
          return "—";
        }

        return competitor2Shots > (competitor1Shots || 0)
          ? `<span class="winner">${competitor2Shots}</span>`
          : `<span>${competitor2Shots}</span>`;
      }
    },

    /**
     * Returns TRUE if the given set number is for a match set with a
     * tie-break; otherwise FALSE.
     *
     * @param {number} setNumber
     * @return {boolean}
     */
    isTieBreakSetNumber(setNumber: number): boolean {
      const matchSet = this.getMatchSetBySetNumber(setNumber);

      return matchSet !== null && matchSet.isTieBreak;
    },
  },
});
