export default class TeamPlayer {
  protected _id: string;
  protected _name: string | null;
  protected _positionId: string | null;
  protected _positionName: string | null;

  constructor(
    id: string,
    name: string | null = null,
    positionId: string | null = null,
    positionName: string | null = null
  ) {
    this._id = id;
    this._name = name;
    this._positionId = positionId;
    this._positionName = positionName;
  }

  /**
   * Returns the ID of the player.
   *
   * @return {string}
   */
  get id(): string {
    return this._id;
  }

  /**
   * Returns the name of the player.
   *
   * @return {string|null}
   */
  get name(): string | null {
    return this._name;
  }

  /**
   * Returns the position ID of the player.
   *
   * @return {string|null}
   */
  get positionId(): string | null {
    return this._positionId;
  }

  /**
   * Returns the position name of the player.
   *
   * @return {string|null}
   */
  get positionName(): string | null {
    return this._positionName;
  }
}
