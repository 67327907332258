export default class Format {
  protected _id: string;
  protected _name: string;
  protected _specialisation: string | null;
  protected _canonicalId: string | null;

  constructor(
    id: string,
    name: string,
    specialisation: string | null = null,
    canonicalId: string | null = null
  ) {
    this._id = id;
    this._name = name;
    this._specialisation = specialisation;
    this._canonicalId = canonicalId;
  }

  /**
   * Returns the ID of the format.
   *
   * @return {string}
   */
  get id(): string {
    return this._id;
  }

  /**
   * Returns the canonical ID of the format if it is set
   * otherwise fallback to the format ID.
   *
   * @return {string}
   */
  get canonicalId(): string {
    return this._canonicalId ?? this._id;
  }

  /**
   * Returns the name of the format.
   *
   * @return {string}
   */
  get name(): string {
    return this._name;
  }

  /**
   * Returns the specialisation of the format.
   *
   * @return {string|null}
   */
  get specialisation(): string | null {
    return this._specialisation;
  }
}
