import {
  CompetitionModel,
  MatchModel,
  CompetitorModel,
  EndModel,
  ApiParser,
  Match as MatchAPI,
  MatchTransformer,
  CompetitionEventModel,
  OwnerEntity,
  CompetitorPlayerModel,
} from "@memberpoint/ba-result-components";
import http from "@/lib/AppHttpResource";
import Configuration from "@/lib/Configuration";
import { AxiosResponse } from "axios";
import MatchResult from "@/Classes/MatchResult";
import { ScoringOptions, LooseObject, SelectItem } from "@/types";
import MatchExtModel from "@/Classes/MatchExtModel";
import MatchCapabilities from "@/Classes/MatchCapabilities";

export interface MatchPayload {
  match: MatchExtModel;
  ends?: Array<EndModel>;
  scoringOptions: ScoringOptions;
}

export interface MatchesPayload {
  matches: MatchExtModel[];
  scoringOptions?: ScoringOptions;
}

export interface MatchOptions {
  currentRound?: boolean;
  section?: number | null;
}

export interface GetCompetitionsOptions {
  organisingBody?: string;
  scorableContexts?: string | string[];
  event?: string;
  sort?: string;
  sortDir?: "ASC" | "DESC";
}

export interface ClubOwnerAttributes {
  id: string;
  name: string;
  logoUrl: string | null;
}

export interface PlayerAttribute {
  id: string;
  firstName: string;
  fullName: string;
  lastName: string;
  position: string;
  shortName: string;
}

export type PlayersPayloadAttributes = Record<string, PlayerAttribute | null>;

export class CompetitionScoringManager {
  public static getErrorMessagesFromResponse(
    response: AxiosResponse
  ): Array<string> {
    const errors: Array<string> = [];

    if (
      typeof response.data === "object" &&
      Object.prototype.hasOwnProperty.call(response.data, "errors") &&
      Array.isArray(response.data.errors)
    ) {
      response.data.errors.forEach((error: Error) => {
        if (
          typeof error === "object" &&
          Object.prototype.hasOwnProperty.call(error, "message")
        ) {
          errors.push(error.message);
        }
      });
    }

    return errors;
  }

  /**
   * Remove the end from the owner.
   *
   * @param {string} ownerHandle
   * @param {?number} endNumber
   */
  removeEnd(
    ownerHandle: string,
    endNumber: number | null = null
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/owner/${ownerHandle}/ends`;

      const attributes: LooseObject = {
        endNumber: null,
      };

      if (typeof endNumber === "number") {
        attributes.endNumber = endNumber;
      }

      http
        .delete(url, attributes)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Mark the supplied match as completed.
   *
   * @param {string} matchID The ID of the match to complete
   */
  markMatchAsCompleted(matchID: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/completed`;

      http
        .put(url)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Mark the supplied match as a forfeit.
   *
   * @param {string} matchID The ID of the match to update
   * @param forfeitCompetitorId
   */
  markMatchAsForfeit(
    matchID: string,
    forfeitCompetitorId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/forfeit`;

      http
        .put(url, {
          attributes: { competitorId: forfeitCompetitorId },
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Mark the supplied match as un-played
   * @param {string} matchID The ID of the match to update
   * @param {string} unPlayedReason
   */
  markMatchAsUnPlayed(matchID: string, unPlayedReason: string): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/unplayed`;

      http
        .put(url, {
          attributes: { reason: unPlayedReason },
        })
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get the competition matching that the current user is a
   * marker of.
   *
   * @param {string} id
   */
  getCompetition(id: string): Promise<CompetitionModel> {
    return new Promise<CompetitionModel>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/competitions/${id}`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(
            this._createCompetitionData(
              data[0].id,
              data[0].attributes as LooseObject
            )
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the competitions available for the selected marker.
   *
   * @param {GetCompetitionsOptions} options
   *
   * @return {Promise<CompetitionModel[]>}
   */
  getCompetitionsForMarker(
    options: GetCompetitionsOptions = {}
  ): Promise<CompetitionModel[]> {
    return new Promise<CompetitionModel[]>((resolve, reject) => {
      let url = `${Configuration.apiBaseUrl}/app/competition-scoring/competitions`;

      const hasProperty = Object.prototype.hasOwnProperty;

      const queryParams = [];

      if (hasProperty.call(options, "organisingBody")) {
        queryParams.push(`organising_body=${options.organisingBody}`);
      }

      if (hasProperty.call(options, "event")) {
        queryParams.push(`event=${options.event}`);
      }

      if (hasProperty.call(options, "scorableContexts")) {
        let scorableContexts: string[] = [];

        if (typeof options.scorableContexts === "string") {
          scorableContexts.push(options.scorableContexts);
        } else if (Array.isArray(options.scorableContexts)) {
          scorableContexts = options.scorableContexts;
        }

        if (scorableContexts.length > 0) {
          queryParams.push(`scorable_context=${scorableContexts.join(",")}`);
        }
      }

      if (hasProperty.call(options, "sort")) {
        queryParams.push(`sort=${options.sort}`);
      }

      if (hasProperty.call(options, "sortDir")) {
        queryParams.push(`sort_dir=${options.sortDir}`);
      }

      if (queryParams.length > 0) {
        url += "?" + queryParams.join("&");
      }

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          const competitions: Array<CompetitionModel> = data.map(
            (row: LooseObject) => {
              return this._createCompetitionData(
                row.id as string,
                row.attributes as LooseObject
              );
            }
          );

          resolve(competitions);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the matches available for a competition for the selected marker.
   *
   * @param {string} competitionId
   * @param {object} options
   *
   * @return {Promise<MatchesPayload>}
   */
  getMatchesForMarker(
    competitionId: string,
    options: MatchOptions = {}
  ): Promise<MatchesPayload> {
    return new Promise<MatchesPayload>((resolve, reject) => {
      let url = `${Configuration.apiBaseUrl}/app/competition-scoring/competitions/${competitionId}/matches`;

      const params = [];

      if (typeof options.currentRound === "boolean") {
        params.push(`current_round=${options.currentRound}`);
      }

      if (typeof options.section === "number") {
        params.push(`section=${options.section}`);
      }

      if (params.length > 0) {
        url += "?" + params.join("&");
      }

      http
        .get(url)
        .then((response) => {
          const parsed = ApiParser.parse(response.data);

          const matches: MatchExtModel[] = (
            (parsed.match as Array<MatchAPI>) || []
          ).map((match: MatchAPI) => {
            return this._createMatchFromAPIModel(match);
          });

          resolve({ matches });
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get a list of ends for owner that the current user is a marker of.
   *
   * @param {string} ownerHandle
   * @return {Promise<EndModel[]>}
   */
  getEndsForOwner(ownerHandle: string): Promise<EndModel[]> {
    return new Promise<EndModel[]>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/owner/${ownerHandle}/ends`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          const ends: Array<EndModel> = data.map((row: LooseObject) => {
            return this._createEndFromData(
              row.id as string,
              row.attributes as LooseObject
            );
          });

          resolve(ends);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the match capabilities for the current suer.
   *
   * @param {string} matchID
   * @return {Promise<MatchCapabilities>}
   */
  getMatchCapabilitiesForCurrentUser(
    matchID: string
  ): Promise<MatchCapabilities> {
    return new Promise<MatchCapabilities>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/capabilities/current`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(new MatchCapabilities(data[0].attributes));
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the matches available for a competition for the user.
   *
   * @param {string} matchID
   * @return {Promise<MatchesPayload>}
   */
  getMatchForMarker(matchID: string): Promise<MatchPayload> {
    return new Promise<MatchPayload>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          const attributes: LooseObject = data[0].attributes as LooseObject;

          const payload: MatchPayload = {
            match: this._createMatchFromData(data[0]),
            scoringOptions: attributes.scoringOptions as ScoringOptions,
            ends: ((attributes.ends || []) as Array<LooseObject>).map((end) => {
              return this._createEndFromData(end.id as string, end);
            }),
          };

          resolve(payload);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Create an end for an owner.
   *
   * @param ownerHandle
   * @param attributes
   */
  createEndForOwner(
    ownerHandle: string,
    attributes: {
      endNumber: number;
      competitorOneScore: number;
      competitorTwoScore: number;
    }
  ): Promise<EndModel> {
    return new Promise<EndModel>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/owner/${ownerHandle}/ends`;

      http
        .post(url, { attributes })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(
            this._createEndFromData(
              data[0].id as string,
              data[0].attributes as LooseObject
            )
          );
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the organising bodies as options for the current user.
   *
   * @return {Promise<SelectItem<string>[]>}
   */
  getOrganisingBodyOptions(): Promise<SelectItem<string>[]> {
    return new Promise<SelectItem<string>[]>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/organising-bodies/options`;

      http
        .get(url)
        .then((response) => {
          const data = response.data || {};

          const options: SelectItem<string>[] = [];

          for (const value in data) {
            if (Object.prototype.hasOwnProperty.call(data, value)) {
              options.push({
                text: data[value] as string,
                value: value as string,
              });
            }
          }

          resolve(options);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Get the events as options for the current user.
   *
   * @return {Promise<SelectItem<string>[]>}
   */
  getEventOptions(): Promise<SelectItem<string>[]> {
    return new Promise<SelectItem<string>[]>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/events/options`;

      http
        .get(url)
        .then((response) => {
          const data = response.data || {};

          const options: SelectItem<string>[] = [];

          for (const value in data) {
            if (Object.prototype.hasOwnProperty.call(data, value)) {
              options.push({
                text: data[value] as string,
                value: value as string,
              });
            }
          }

          resolve(options);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }

  /**
   * Returns the CompetitionModel from the given data.
   *
   * @param {string} id
   * @param {object} attributes
   */
  _createCompetitionData(
    id: string,
    attributes: LooseObject
  ): CompetitionModel {
    const competition = new CompetitionModel(
      (attributes.title || "") as string,
      id,
      (attributes.status as LooseObject).code as string,
      typeof attributes.startDate === "string"
        ? this._convertDate(attributes.startDate)
        : 0,
      typeof attributes.endDate === "string"
        ? this._convertDate(attributes.endDate)
        : 0,
      (attributes.description || "") as string
    );

    if (typeof attributes.resultProfileType === "string") {
      competition.setResultType(attributes.resultProfileType);
    }

    if (typeof attributes.eventId === "string") {
      const competitionEvent = new CompetitionEventModel(
        (attributes.eventTitle || "") as string,
        attributes.eventId as string,
        "",
        null,
        null
      );

      competition.setEvent(competitionEvent);
    }

    competition.setEventLabel((attributes.eventTitle || "") as string);

    if (typeof attributes.primaryOrganisingBody === "string") {
      competition.setOwnerLabel(attributes.primaryOrganisingBody as string);
    }

    if (typeof attributes.logoUrl === "string") {
      competition.setLogoUrl(attributes.logoUrl as string);
    }

    if (typeof attributes.format === "string") {
      competition.setFormat(attributes.format);
    }

    return competition;
  }

  /**
   * Returns an instance of an end from the API data.
   *
   * @param {string} id
   * @param {LooseObject} attributes
   * @return {EndModel}
   */
  _createEndFromData(id: string, attributes: LooseObject): EndModel {
    return new EndModel(
      id,
      attributes.endNumber as number,
      attributes.state as string,
      attributes.timeStarted as number,
      attributes.competitorOneShots as number,
      attributes.competitorTwoShots as number
    );
  }

  /**
   * Returns an instance of the Match from the API data.
   *
   * @param data
   * @return {MatchExtModel}
   */
  _createMatchFromData(data: LooseObject): MatchExtModel {
    const attributes: LooseObject = data.attributes as Record<string, unknown>;

    let competitor1Attrs: string | Record<string, unknown> = "";
    let competitor2Attrs: string | Record<string, unknown> = "";

    if (
      attributes.competitorOne &&
      (typeof attributes.competitorOne === "string" ||
        typeof attributes.competitorOne === "object")
    ) {
      competitor1Attrs = attributes.competitorOne as
        | string
        | Record<string, unknown>;
    }

    if (
      attributes.competitorTwo &&
      (typeof attributes.competitorTwo === "string" ||
        typeof attributes.competitorTwo === "object")
    ) {
      competitor2Attrs = attributes.competitorTwo as
        | string
        | Record<string, unknown>;
    }

    const competitor1 = this._createCompetitorFromAttributes(competitor1Attrs);
    const competitor2 = this._createCompetitorFromAttributes(competitor2Attrs);

    const matchData: LooseObject = attributes.match as LooseObject;

    const match = new MatchExtModel(
      (matchData.id || "") as string,
      competitor1,
      competitor2,
      matchData.round as number,
      matchData.section as number,
      {
        key: "",
        label: "",
      },
      typeof matchData.date === "number" ? matchData.date * 1000 : 0,
      0,
      matchData.timezone as string
    );

    if (attributes.isFinalsSeries === true) {
      match.setIsFinalsSeries(true);
    }

    const competition = this._createCompetitionData(
      (matchData.competitionId || "") as string,
      {
        title: matchData.competitionName || "",
        status: {
          code: "",
        },
        resultProfileType: attributes.resultProfileType || "",
        format: attributes.format,
      }
    );

    match.setCompetition(competition);

    if (typeof attributes.result === "object") {
      const result = attributes.result as LooseObject;

      let status = result.status as string;

      if (!status && attributes.hasResults === true) {
        status = "success";
      }

      const matchResult = new MatchResult(
        result.handle as string,
        result.competitorOneShots as number | null,
        result.competitorTwoShots as number | null,
        result.competitorOnePoints as number | null,
        result.competitorTwoPoints as number | null,
        status as string,
        result.wonBy as number | null,
        result.isFinalized as boolean,
        result.hasConfirmation as boolean
      );

      match.setResult(matchResult);
    }

    match.setCompetitionLabel((matchData.competitionName || "") as string);
    match.setVenueLabel((matchData.address || "") as string);

    const meta = (data.meta || {}) as LooseObject;

    if (Array.isArray(meta.competitionScorableContexts)) {
      match.setScorableContexts(meta.competitionScorableContexts);
    }

    if (typeof meta.isCurrentUserHomeCompetitor === "boolean") {
      match.setIsCurrentUserHomeCompetitor(meta.isCurrentUserHomeCompetitor);
    }

    if (typeof meta.isCurrentUserAwayCompetitor === "boolean") {
      match.setIsCurrentUserAwayCompetitor(meta.isCurrentUserAwayCompetitor);
    }

    if (data.capabilities && typeof data.capabilities === "object") {
      match.setMatchCapabilities(
        new MatchCapabilities(data.capabilities as Record<string, boolean>)
      );
    }

    if (typeof attributes.poolName === "string") {
      match.setPoolName(attributes.poolName);
    }

    if (typeof attributes.roundName === "string") {
      match.setRoundName(attributes.roundName);
    }

    if (
      attributes.resultOptions &&
      typeof attributes.resultOptions === "object"
    ) {
      match.setResultOptions(
        attributes.resultOptions as Record<string, unknown>
      );
    }

    return match;
  }

  /**
   * Returns the match model from MatchAPI.
   *
   * @param {MatchExtModel} matchAPI
   */
  _createMatchFromAPIModel(matchAPI: MatchAPI): MatchExtModel {
    const matchModel: MatchModel = MatchTransformer(matchAPI);

    const match: MatchExtModel = MatchExtModel.createFromMatchModel(matchModel);

    if (matchAPI.has("scorableContexts")) {
      const scorableContexts = (matchAPI.getAttributes()["scorableContexts"] ||
        []) as string[];

      match.setScorableContexts(scorableContexts);
    }

    if (
      matchAPI.isCompetitorOneBye &&
      match.competitorOne instanceof CompetitorModel
    ) {
      match.competitorOne.setIsBye();
    }

    if (
      matchAPI.isCompetitorTwoBye &&
      match.competitorTwo instanceof CompetitorModel
    ) {
      match.competitorTwo.setIsBye();
    }

    if (matchAPI.result) {
      let isFinalized = false;

      const attributes = (matchAPI.result.getAttributes() || {}) as LooseObject;

      if (
        Object.prototype.hasOwnProperty.call(attributes, "isFinalized") &&
        typeof attributes.isFinalized === "boolean"
      ) {
        isFinalized = attributes.isFinalized;
      }

      let hasConfirmation = false;

      if (
        Object.prototype.hasOwnProperty.call(attributes, "hasConfirmation") &&
        typeof attributes.hasConfirmation === "boolean"
      ) {
        hasConfirmation = attributes.hasConfirmation;
      }

      const matchResult = new MatchResult(
        "",
        matchAPI.result.competitorOneScore,
        matchAPI.result.competitorTwoScore,
        matchAPI.result.competitorOnePoints,
        matchAPI.result.competitorOnePoints,
        matchAPI.result.status,
        null,
        isFinalized,
        hasConfirmation
      );

      match.setResult(matchResult);
    }

    if (matchAPI.competition) {
      const pool = match.pool.toString();
      const round = match.round.toString();

      match.setPoolName(matchAPI.competition.getNameForPool(pool));
      match.setRoundName(matchAPI.competition.getNameForRound(pool, round));
    }

    return match;
  }

  _convertDate(date: string): number {
    return Date.parse(date);
  }

  /**
   * Returns the sanitized string from given value.
   *
   * @param {*} value
   * @return {string|null}
   */
  _sanitizeString(value: unknown): string | null {
    if (typeof value === "string") {
      const trimmed = value.trim();

      return trimmed.length > 0 ? trimmed : null;
    }

    return null;
  }

  /**
   * Returns an instance of the CompetitorModel from the given attributes.
   *
   * @param {Record<string, unknown> | string} attributes
   * @return {CompetitorModel}
   */
  _createCompetitorFromAttributes(
    attributes: Record<string, unknown> | string
  ): CompetitorModel {
    let competitor = new CompetitorModel("0", "TBC", "TBC");

    competitor.setIsTbc();

    if (typeof attributes === "string" && attributes === "BYE") {
      competitor = new CompetitorModel("0", "Bye", "Bye");
      competitor.setIsBye();
    } else if (typeof attributes === "object") {
      competitor = new CompetitorModel(
        attributes.id as string,
        (attributes.name || "") as string,
        (attributes.shortName || "") as string,
        this._sanitizeString(attributes.logoUrl)
      );

      competitor.setColor((attributes.color || "") as string);

      // Set owner if there is a club owner for competitor 1.
      if (attributes.owner && typeof attributes.owner === "object") {
        const club = attributes.owner as ClubOwnerAttributes;
        const owner = new OwnerEntity("CLUB", club.id, club.name, "");

        owner.logoUrl = this._sanitizeString(club.logoUrl);

        competitor.setOwner(owner);
      }

      // Add team players.
      if (attributes.players && typeof attributes.players === "object") {
        for (const position in attributes.players) {
          if (
            Object.prototype.hasOwnProperty.call(attributes.players, position)
          ) {
            const playerAttribute = (attributes.players as PlayersPayloadAttributes)[
              position
            ];

            if (playerAttribute !== null) {
              const player = new CompetitorPlayerModel(
                playerAttribute.id,
                playerAttribute.firstName,
                playerAttribute.lastName,
                playerAttribute.fullName,
                playerAttribute.shortName,
                playerAttribute.position
              );

              competitor.addPlayer(player);
            }
          }
        }
      }
    }

    return competitor;
  }
}
