import { DirectiveOptions } from "vue";
import PullToRefresh, { PullToRefreshInstance, Options } from "pulltorefreshjs";
import { mdiRefresh } from "@mdi/js";

interface PullToRefreshInstanceExt extends PullToRefreshInstance {
  _originalTop?: number | null;
}

interface PullToRefreshElement extends HTMLElement {
  _pullToRefresh: PullToRefreshInstanceExt;
}

// Helper to override properties.
type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;

type OptionsExt = Overwrite<
  Options,
  {
    mainElement?: string | HTMLElement | Element;
    triggerElement?: string | HTMLElement | Element;
  }
>;

const convertIcon = (icon: string): string => {
  return `<svg role="img" class="v-icon__svg">
        <path d="${icon}"></path>
      </svg>`;
};

const directive: DirectiveOptions = {
  inserted(el, binding) {
    const defaultOptions: OptionsExt = {
      triggerElement: el,
      mainElement: el,
      onRefresh(): void {
        window.location.reload();
      },
      shouldPullToRefresh(): boolean {
        const mainElement = this.mainElement as PullToRefreshElement;

        // Only pull if we are at the original top position.
        return (
          mainElement._pullToRefresh._originalTop ===
          mainElement.getBoundingClientRect().top
        );
      },
      iconArrow: convertIcon(mdiRefresh),
    };

    (el as PullToRefreshElement)._pullToRefresh = PullToRefresh.init(
      Object.assign(defaultOptions, binding.value || {})
    );

    setTimeout(() => {
      const position = el.getBoundingClientRect();

      // Save the original position to check whether we should pull to refresh
      // @see shouldPullToRefresh() above.
      (el as PullToRefreshElement)._pullToRefresh._originalTop = position.top;
    }, 50);
  },
  unbind(el) {
    if (Object.prototype.hasOwnProperty.call(el, "_pullToRefresh")) {
      (el as PullToRefreshElement)._pullToRefresh.destroy();
    }
  },
};

export default directive;
