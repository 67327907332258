





























































































import { CompetitionModel } from "@memberpoint/ba-result-components";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import MatchHeaderSkeletonLoader from "@/controllers/app/marker-matches/scorer/_internal/MatchHeaderSkeletonLoader.vue";
import StandardResultOverview from "@/controllers/app/marker-match-finder/tabs/_internal/StandardResultOverview.vue";
import { LooseObject, ScoringOptions } from "@/types";
import SideShotResultOverview from "@/controllers/app/marker-match-finder/tabs/_internal/SideShotResultOverview.vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import SideMatch from "@/Classes/SideMatch";
import PullToRefresh from "@/directives/PullToRefresh";
import MatchExtModel from "@/Classes/MatchExtModel";
import SetResultOverview from "@/controllers/app/marker-match-finder/tabs/_internal/SetResultOverview.vue";
import MatchSet from "@/Classes/MatchSet";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof MatchDetailsMixin>
>).extend({
  name: "MatchTab",

  components: {
    SetResultOverview,
    SideShotResultOverview,
    StandardResultOverview,
    MatchHeaderSkeletonLoader,
  },

  directives: {
    pullToRefresh: PullToRefresh,
  },

  mixins: [MatchDetailsMixin],

  props: {
    match: {
      type: [MatchExtModel, String] as PropType<MatchExtModel | string>,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,
  },

  data() {
    return {
      loadingMatch: false,
      loadingMatchError: null as string | null,
      loadedScoringOptions: {} as ScoringOptions,
      matchData: null as MatchExtModel | null,
      selectedSideMatchID: null as string | null,
      selectedMatchSetID: null as string | null,
    };
  },

  computed: {
    /**
     * Returns TRUE if we should display the actions; otherwise FALSE.
     *
     * @return {boolean}
     */
    canDisplayActions(): boolean {
      if (!this.loadedScoringOptions.hasEndScoring) {
        return false;
      }

      if (this.matchData === null) {
        return false;
      }

      if (this.isFinalized) {
        return false;
      }

      if (this.hasTBDCompetitors) {
        return false;
      }

      return !(this.status === "non-played" || this.status === "forfeit");
    },

    /**
     * Returns the result type of the competition.
     *
     * @return {string|null}
     */
    resultType(): string | null {
      if (
        this.matchData === null ||
        !(this.matchData.competition instanceof CompetitionModel)
      ) {
        return null;
      }

      return (this.matchData.competition as CompetitionModel).resultType;
    },

    /**
     * Returns the match ID.
     *
     * @return {string}
     */
    matchID(): string {
      if (typeof this.match === "string") {
        return this.match;
      }

      return this.match.id;
    },
  },

  watch: {
    matchID: {
      handler(): void {
        this.load();
      },
    },
  },

  created() {
    this.load();
  },

  methods: {
    refresh() {
      this.load();
    },

    load(): void {
      if (typeof this.match === "string") {
        this.loadMatch(this.match);
      } else {
        this.matchData = this.match;
        this.loadedScoringOptions = this.scoringOptions;
      }
    },

    /**
     * Load the match details.
     *
     * @param {string} matchID
     */
    loadMatch(matchID: string) {
      if (this.loadingMatch) {
        return;
      }

      this.loadingMatch = true;
      this.loadingMatchError = null;

      const manager = new CompetitionScoringManager();

      manager
        .getMatchForMarker(matchID)
        .then((matchPayload) => {
          this.matchData = matchPayload.match;
          this.loadedScoringOptions = matchPayload.scoringOptions;
        })
        .catch((response) => {
          let message;

          if (response.status === 403) {
            message = "You do not have permission to score the match.";
          } else {
            message = ApiResponseHelper.getErrorMessageFromResponse(response);

            if (message === null) {
              message = "There was an error loading the match details.";
            }
          }

          this.loadingMatchError = message;
        })
        .finally(() => {
          this.loadingMatch = false;
        });
    },

    /**
     * Handle the "click" event on the score match button.
     */
    onScoreMatchClick(): void {
      const params: LooseObject = {};

      if (this.selectedSideMatchID) {
        params.sideMatchId = this.selectedSideMatchID;
      }

      if (this.selectedMatchSetID) {
        params.matchSetId = this.selectedMatchSetID;
      }

      this.$emit("score-match-click", this.matchData, params);
    },

    /**
     * Handle the "side-match-selected" event that is emitted
     * from the SideShotResultOverview component.
     *
     * @param {SideMatch|null} sideMatch
     */
    onSideMatchSelected(sideMatch: SideMatch | null): void {
      if (sideMatch !== null) {
        this.selectedSideMatchID = sideMatch.id;
      } else {
        this.selectedSideMatchID = null;
      }
    },

    /**
     * Handle the "match-set-selected" event that is emitted
     * from the SetResultOverview component.
     *
     * @param {MatchSet|null} matchSet
     */
    onMatchSetSelected(matchSet: MatchSet | null): void {
      if (matchSet !== null) {
        this.selectedMatchSetID = matchSet.id;
      } else {
        this.selectedMatchSetID = null;
      }
    },
  },
});
