import { AxiosResponse } from "axios";
import _get from "lodash/get";

export default class ApiResponseHelper {
  /**
   * Returns the single error message from the given response or
   * NULL if could not do so.
   *
   * @param {AxiosResponse} response
   */
  static getErrorMessageFromResponse(response: AxiosResponse): string | null {
    let message = null;

    if (
      response &&
      typeof response === "object" &&
      Object.prototype.hasOwnProperty.call(response, "data")
    ) {
      const data = response.data || {};

      let error = null;

      if (Array.isArray(data.errors) && data.errors.length > 0) {
        /*
         * Case data is:
         * data = {
         *    errors: [
         *      { message: "..." }
         *    ]
         * }
         */
        if (data.errors[0] && typeof data.errors[0] === "object") {
          error = data.errors[0];
        }
      } else if (data && typeof data === "object") {
        /*
         * Case data is:
         * data = {
         *    data: [
         *      { attributes: { message: "..." } }
         *    ]
         * }
         */
        if (Array.isArray(data.data) && data.data.length > 0) {
          error = _get(data, "data[0].attributes");
        }
      }

      // If the error object is resolved and has the "message" property.
      if (
        error &&
        typeof error === "object" &&
        Object.prototype.hasOwnProperty.call(error, "message") &&
        typeof error.message === "string"
      ) {
        message = error.message;
      }
    }

    return message;
  }
}
