import MatchSet from "@/Classes/MatchSet";
import Configuration from "@/lib/Configuration";
import http from "@/lib/AppHttpResource";
import { LooseObject } from "@/types";
import MatchCapabilities from "@/Classes/MatchCapabilities";

export interface MatchTotalSetScoresPayload {
  competitorOneTotalShots: number;
  competitorTwoTotalShots: number;
  competitorOneSetsWon: number;
  competitorTwoSetsWon: number;
  nonScoredSets: string[];
  matchCapabilities: MatchCapabilities;
}

export default class SetResultManager {
  /**
   * Get the sets for the match.
   *
   * @param {string} matchID
   * @param {boolean} initializeSet
   * @return {Promise<MatchSet[]>}
   */
  getSetsForMatch(matchID: string, initializeSet = false): Promise<MatchSet[]> {
    return new Promise<MatchSet[]>((resolve, reject) => {
      let url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/sets`;

      if (initializeSet) {
        url += "?initialize_set=1";
      }

      http
        .get(url)
        .then((response) => {
          const matchSetsData = response.data.data || [];

          const matchSets = matchSetsData.map((row: LooseObject) => {
            return this._createMatchSetFromData(
              row.id as string,
              row.attributes as LooseObject
            );
          });

          resolve(matchSets);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Create the next set for the match.
   *
   * @param {string} matchID
   * @param {boolean} isTieBreak
   * @return {Promise<MatchSet>}
   */
  createNextSet(matchID: string, isTieBreak = false): Promise<MatchSet> {
    return new Promise<MatchSet>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/sets/next`;

      http
        .post(url, { attributes: { isTieBreak } })
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(
            this._createMatchSetFromData(
              data[0].id as string,
              data[0].attributes as LooseObject
            )
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Get the total set scores for the match.
   *
   * @param {string} matchID
   * @return {Promise<MatchTotalSetScoresPayload>}
   */
  getMatchState(matchID: string): Promise<MatchTotalSetScoresPayload> {
    return new Promise<MatchTotalSetScoresPayload>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/sets/state`;

      http
        .get(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          const attributes = data[0].attributes as LooseObject;

          resolve({
            competitorOneTotalShots: attributes.competitorOneTotalShots as number,
            competitorTwoTotalShots: attributes.competitorTwoTotalShots as number,
            competitorOneSetsWon: attributes.competitorOneSetsWon as number,
            competitorTwoSetsWon: attributes.competitorTwoSetsWon as number,
            nonScoredSets: (attributes.nonScoredSets || []) as string[],
            matchCapabilities: new MatchCapabilities(data[0].capabilities),
          });
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Remove the set at the given set number for the match.
   *
   * @param {string} matchID
   * @param {number} setNumber
   * @return {Promise<MatchSet>}
   */
  removeSet(matchID: string, setNumber: number): Promise<MatchSet> {
    return new Promise<MatchSet>((resolve, reject) => {
      const url = `${Configuration.apiBaseUrl}/app/competition-scoring/matches/${matchID}/sets/${setNumber}`;

      http
        .delete(url)
        .then((response) => {
          const data = response.data.data || [];

          if (data.length !== 1) {
            return reject("INVALID_DATA");
          }

          resolve(
            this._createMatchSetFromData(
              data[0].id as string,
              data[0].attributes as LooseObject
            )
          );
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  /**
   * Returns an instance of MatchSet from the data.
   *
   * @param {string} id
   * @param {Record} attributes
   */
  _createMatchSetFromData(id: string, attributes: LooseObject): MatchSet {
    return new MatchSet(
      id,
      attributes.handle as string,
      attributes.matchId as string,
      attributes.setNumber as number,
      attributes.competitorOneShots as number,
      attributes.competitorTwoShots as number,
      attributes.isTieBreak as boolean
    );
  }
}
