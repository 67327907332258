






































































import Vue, { PropOptions, PropType } from "vue";
import { SelectItem } from "@/types";
import { mdiChevronDown } from "@mdi/js";

export default Vue.extend({
  name: "BottomSheetSelection",

  props: {
    items: {
      type: Array as PropType<SelectItem<unknown>[]>,
      required: false,
      default() {
        return [];
      },
    } as PropOptions<SelectItem<unknown>[]>,

    placeholder: {
      type: String,
      required: false,
      default: "",
    },

    loading: {
      type: Boolean,
      required: false,
      default: false,
    },

    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },

    bottomSheetProps: {
      type: Object as PropType<Record<string, unknown>>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<Record<string, unknown>>,

    value: {
      required: false,
      default: null,
      validator(v) {
        return v !== undefined;
      },
    },
  },

  data() {
    return {
      showMenu: false,
      selectedIndex: null as number | null,
      dropdownIcon: mdiChevronDown,
      internalValue: this.value,
    };
  },

  computed: {
    /**
     * Returns the selected item.
     *
     * @return {SelectItem|null}
     */
    selectedItem(): SelectItem<unknown> | null {
      if (this.selectedIndex === null) {
        return null;
      }

      this.$emit("input", this.items[this.selectedIndex].value);

      return this.items[this.selectedIndex];
    },

    activatorLabel(): string {
      return this.selectedItem ? this.selectedItem.text : this.placeholder;
    },
  },

  watch: {
    value: {
      immediate: true,
      handler(nv) {
        if (nv !== null && nv !== undefined) {
          const index = this.items.findIndex((item) => item.value === nv);

          if (index !== -1) {
            this.selectedIndex = index;
          }
        }
      },
    },

    items(nv: SelectItem<unknown>[]) {
      let selectedIndex = null;

      const index = nv.findIndex((item) => item.value === this.value);

      if (index !== -1) {
        selectedIndex = index;
      }

      this.selectedIndex = selectedIndex;
    },
  },

  methods: {
    onItemSelect(index: number) {
      // Only update when changed.
      if (index !== this.selectedIndex) {
        this.selectedIndex = index;
      }

      this.showMenu = false;
    },

    onShowMenu(): void {
      if (this.disabled) {
        return;
      }

      this.showMenu = true;
    },
  },
});
