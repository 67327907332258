import Vue from "vue";
import SideShotResultManager from "@/services/SideShotResultManager";
import {
  CompetitorPlayerModel,
  MatchModel,
} from "@memberpoint/ba-result-components";
import SideMatch from "@/Classes/SideMatch";
import Team from "@/Classes/Team";

export default Vue.extend({
  data() {
    return {
      loadingSideMatches: false,
    };
  },

  methods: {
    /**
     * Load the side matches for the given match.
     *
     * @param {MatchModel} match
     * @return {Promise<SideMatch[]>}
     */
    loadSideMatchesForMatch(match: MatchModel): Promise<SideMatch[]> {
      if (this.loadingSideMatches) {
        return Promise.reject("ALREADY_LOADING");
      }

      this.loadingSideMatches = true;

      const sideShotManager = new SideShotResultManager();

      return sideShotManager
        .getSideMatches(match.id)
        .then((sideMatches) => {
          return Promise.resolve(sideMatches);
        })
        .finally(() => {
          this.loadingSideMatches = false;
        });
    },

    /**
     * Returns the dummy team players based on the side match's format.
     * These dummy objects are to represent empty players mainly for the scorecard
     * to display as a fallback.
     *
     * @param {SideMatch} sideMatch
     * @return {CompetitorPlayerModel[]}
     */
    createEmptyTeamPlayersForSelectedSideMatch(
      sideMatch: SideMatch
    ): CompetitorPlayerModel[] {
      const positions = this._getExpectedPlayerPositionsForSideMatch(sideMatch);

      return positions.map((position) => {
        return new CompetitorPlayerModel(
          "",
          " - ",
          " - ",
          " - ",
          " - ",
          position
        );
      });
    },

    /**
     * Returns the expected player positions from the side match.
     *
     * @return {string[]}
     *
     * @private
     */
    _getExpectedPlayerPositionsForSideMatch(sideMatch: SideMatch): string[] {
      switch (sideMatch.format.canonicalId) {
        case "singles":
          return ["skip"];
        case "pairs":
          return ["lead", "skip"];
        case "triples":
          return ["lead", "second", "skip"];
      }

      return ["lead", "second", "third", "skip"];
    },

    /**
     * Returns an array of CompetitorPlayerModel based on the Team
     * instance.
     *
     * @param {Team} team
     * @return {CompetitorPlayerModel[]}
     *
     * @private
     */
    _createPlayersFromTeam(team: Team): CompetitorPlayerModel[] {
      return team.teamPlayers.map((teamPlayer) => {
        return new CompetitorPlayerModel(
          teamPlayer.id,
          "",
          "",
          teamPlayer.name ?? "",
          "",
          teamPlayer.positionId ?? ""
        );
      });
    },
  },
});
