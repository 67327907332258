export default class MatchSet {
  protected _id: string;
  protected _handle: string;
  protected _matchID: string;
  protected _setNumber: number;
  protected _competitorOneShots: number | null;
  protected _competitorTwoShots: number | null;
  protected _isTieBreak: boolean;

  constructor(
    id: string,
    handle: string,
    matchID: string,
    setNumber: number,
    competitorOneShots: number | null = null,
    competitorTwoShots: number | null = null,
    isTieBreak = false
  ) {
    this._id = id;
    this._handle = handle;
    this._matchID = matchID;
    this._setNumber = setNumber;
    this._competitorOneShots = competitorOneShots;
    this._competitorTwoShots = competitorTwoShots;
    this._isTieBreak = isTieBreak;
  }

  /**
   * Returns the ID of the set.
   *
   * @return {string}
   */
  get id(): string {
    return this._id;
  }

  /**
   * Returns the handle of the set.
   *
   * @return {string}
   */
  get handle(): string {
    return this._handle;
  }

  /**
   * Returns the match ID.
   *
   * @return {string}
   */
  get matchID(): string {
    return this._matchID;
  }

  /**
   * Returns the set number.
   *
   * @return {number}
   */
  get setNumber(): number {
    return this._setNumber;
  }

  /**
   * Returns the competitor 1 shots.
   *
   * @return {number}
   */
  get competitorOneShots(): number | null {
    return this._competitorOneShots;
  }

  /**
   * Returns the competitor 2 shots.
   *
   * @return {number}
   */
  get competitorTwoShots(): number | null {
    return this._competitorTwoShots;
  }

  /**
   * Returns TRUE if the set is a tie-break; otherwise FALSE.
   *
   * @return {boolean}
   */
  get isTieBreak(): boolean {
    return this._isTieBreak;
  }
}
