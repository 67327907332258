






















































































































































































































































import Vue from "vue";
import {
  CompetitionEventModel,
  CompetitionModel,
} from "@memberpoint/ba-result-components";
import {
  GetCompetitionsOptions,
  CompetitionScoringManager,
} from "@/services/CompetitionScoringManager";
import CompetitionBlock from "@/components/CompetitionBlock/index.vue";
import PullToRefresh from "@/directives/PullToRefresh";
import { FilterOption, SelectItem } from "@/types";
import {
  mdiClose,
  mdiTune,
  mdiSortVariant,
  mdiCalendarMonthOutline,
} from "@mdi/js";

interface CompetitionFilters {
  organisingBody: FilterOption<string | null>;
  event: FilterOption<string | null>;
  onlyForCompetingCompetitions: FilterOption<boolean | null>;
  onlyForMarkingCompetitions: FilterOption<boolean | null>;
}

type CompetitionFilterForm = {
  [K in keyof CompetitionFilters]: CompetitionFilters[K]["value"];
};

type EventGroupedCompetitions = {
  eventName?: string;
  competitions: CompetitionModel[];
};

const EVENTLESS_COMPETITION_GROUP = "__EVENTLESS__";

export default Vue.extend({
  name: "CompetitionsTab",

  components: { CompetitionBlock },

  directives: {
    pullToRefresh: PullToRefresh,
  },

  data() {
    return {
      loadingCompetitions: false,
      loadingCompetitionsErrors: [] as Array<string>,
      competitions: [] as Array<CompetitionModel>,
      eventsGroupedCompetitions: {} as Map<string, EventGroupedCompetitions>,
      selectedCompetition: null as null | CompetitionModel,
      filters: {
        organisingBody: {
          label: "Organising Body",
          value: null,
        },
        event: {
          label: "Event",
          value: null,
        },
        onlyForCompetingCompetitions: {
          label: "Only competing competitions",
          value: null,
        },
        onlyForMarkingCompetitions: {
          label: "Only marking competitions",
          value: null,
        },
      } as CompetitionFilters,
      filterForm: {
        organisingBody: null,
        event: null,
        onlyForCompetingCompetitions: null,
        onlyForMarkingCompetitions: null,
      } as CompetitionFilterForm,
      showFilterDialog: false,
      closeIcon: mdiClose,
      filterIcon: mdiTune,
      sortIcon: mdiSortVariant,
      calendarIcon: mdiCalendarMonthOutline,
      organisingBodyOptions: [] as SelectItem<string>[],
      eventOptions: [] as SelectItem<string>[],
      loadingOrganisingBodyOptions: false,
      loadingEventOptions: false,
      filterStateChecksum: "",
      sortOptions: [
        { text: "Date", value: "dates" },
        { text: "Title", value: "title" },
      ] as SelectItem<string>[],
      currentSort: null as string | null,
    };
  },

  computed: {
    /**
     * Returns TRUE if we should display the filters; otherwise FALSE.
     *
     * @return {boolean}
     */
    canShowFilter(): boolean {
      return this.competitions.length > 1 || this.hasActiveFilters;
    },

    /**
     * Returns TRUE if we should display the sort action button; otherwise FALSE.
     *
     * @return {boolean}
     */
    canShowSortAction(): boolean {
      // TODO: disabled as this does not work with grouping competitions to events.
      return false;

      // return this.competitions.length > 1;
    },

    /**
     * Returns TRUE if there active filters; otherwise FALSE.
     *
     * @return {boolean}
     */
    hasActiveFilters(): boolean {
      for (const filter in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, filter)) {
          if (this.filters[filter as keyof CompetitionFilters].value !== null) {
            return true;
          }
        }
      }

      return false;
    },
  },

  created() {
    this.loadCompetitions();
    this.loadOrganisingBodyOptions();
    this.loadEventOptions();

    this.filterStateChecksum = this._createFilterChecksum();
  },

  methods: {
    /**
     * Load the organising body options.
     */
    loadOrganisingBodyOptions(): void {
      if (this.loadingOrganisingBodyOptions) {
        return;
      }

      this.loadingOrganisingBodyOptions = true;

      const manager = new CompetitionScoringManager();

      manager
        .getOrganisingBodyOptions()
        .then((options: SelectItem<string>[]) => {
          this.organisingBodyOptions = options;
        })
        .finally(() => {
          this.loadingOrganisingBodyOptions = false;
        });
    },

    /**
     * Load the events options.
     */
    loadEventOptions(): void {
      if (this.loadingEventOptions) {
        return;
      }

      this.loadingEventOptions = true;

      const manager = new CompetitionScoringManager();

      manager
        .getEventOptions()
        .then((options: SelectItem<string>[]) => {
          this.eventOptions = options;
        })
        .finally(() => {
          this.loadingEventOptions = false;
        });
    },

    /**
     * Load the competitions.
     */
    loadCompetitions(): void {
      if (this.loadingCompetitions) {
        return;
      }

      this.loadingCompetitions = true;
      this.loadingCompetitionsErrors = [];

      let manager = new CompetitionScoringManager();

      const options: GetCompetitionsOptions = {};

      if (typeof this.filters.organisingBody.value === "string") {
        options.organisingBody = this.filters.organisingBody.value;
      }

      if (typeof this.filters.event.value === "string") {
        options.event = this.filters.event.value;
      }

      const scorableContexts: string[] = [];

      if (this.filters.onlyForCompetingCompetitions.value === true) {
        scorableContexts.push("competitor");
      }

      if (this.filters.onlyForMarkingCompetitions.value === true) {
        scorableContexts.push("marker");
      }

      options.scorableContexts = scorableContexts;

      if (this.currentSort !== null) {
        options.sort = this.currentSort;
      }

      manager
        .getCompetitionsForMarker(options)
        .then((competitions) => {
          this.competitions = competitions;

          // Group competitions to events. If the competition does not
          // belong to an event then they are grouped in the "__EVENTLESS__" event.
          // We are using map to maintain the order of insertion.
          const eventsCompetitions: Map<
            string,
            EventGroupedCompetitions
          > = new Map();

          eventsCompetitions.set(EVENTLESS_COMPETITION_GROUP, {
            competitions: [],
          });

          competitions.forEach((competition) => {
            const event = competition.event;

            let eventId = EVENTLESS_COMPETITION_GROUP,
              eventName = "";

            if (event instanceof CompetitionEventModel) {
              eventId = event.id;
              eventName = event.name;
            }

            if (!eventsCompetitions.has(eventId)) {
              eventsCompetitions.set(eventId, {
                eventName,
                competitions: [],
              });
            }

            const group = eventsCompetitions.get(eventId);

            if (group) {
              group.competitions.push(competition);
            }
          });

          if (
            eventsCompetitions.size === 1 &&
            eventsCompetitions.has(EVENTLESS_COMPETITION_GROUP)
          ) {
            const group = eventsCompetitions.get(EVENTLESS_COMPETITION_GROUP);

            if (!group || group.competitions.length === 0) {
              eventsCompetitions.clear();
            }
          }

          this.eventsGroupedCompetitions = eventsCompetitions;
        })
        .catch((response) => {
          this.loadingCompetitionsErrors = CompetitionScoringManager.getErrorMessagesFromResponse(
            response
          );
        })
        .finally(() => {
          this.loadingCompetitions = false;
        });
    },

    /**
     * Close the filter dialog.
     */
    closeFilterDialog(): void {
      this.showFilterDialog = false;
    },

    /**
     * Handle the filter form submit.
     */
    submitFilter(): void {
      this.filters.organisingBody.value = this.filterForm.organisingBody;
      this.filters.event.value = this.filterForm.event;

      // "FALSE" should be set to NULL so it is not active in the filter
      // because we see active state as only "TRUE" in a toggle switches.
      if (!this.filterForm.onlyForCompetingCompetitions) {
        this.filters.onlyForCompetingCompetitions.value = null;
      } else {
        this.filters.onlyForCompetingCompetitions.value = true;
      }

      if (!this.filterForm.onlyForMarkingCompetitions) {
        this.filters.onlyForMarkingCompetitions.value = null;
      } else {
        this.filters.onlyForMarkingCompetitions.value = true;
      }

      this.closeFilterDialog();

      // Only reload the competitions if the filters have been changed.
      if (this.hasChangedFilter()) {
        this.loadCompetitions();

        // Renew the filter checksum.
        this.filterStateChecksum = this._createFilterChecksum();
      }
    },

    /**
     * Returns TRUE if the filters have changed; otherwise FALSE.
     *
     * @return {boolean}
     */
    hasChangedFilter(): boolean {
      return this.filterStateChecksum !== this._createFilterChecksum();
    },

    /**
     * Remove a selected filter.
     *
     * @param {string|number} filter
     */
    removeFilter(filter: string | number): void {
      const key = filter as keyof CompetitionFilters;

      if (Object.prototype.hasOwnProperty.call(this.filters, key)) {
        this.filters[key].value = null;
        this.filterForm[key] = null;

        this.loadCompetitions();

        this.filterStateChecksum = this._createFilterChecksum();
      }
    },

    /**
     * Clear all active filters.
     */
    clearFilters(): void {
      for (const filter in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, filter)) {
          this.filters[filter as keyof CompetitionFilters].value = null;
        }
      }

      for (const input in this.filterForm) {
        if (Object.prototype.hasOwnProperty.call(this.filterForm, input)) {
          this.filterForm[input as keyof CompetitionFilterForm] = null;
        }
      }

      this.loadCompetitions();

      this.filterStateChecksum = this._createFilterChecksum();
    },

    /**
     * Returns the value of the filter that is readable for display.
     *
     * @param {string|number} filter
     * @return {*|null}
     */
    getReadableFilterValue(filter: string | number): unknown {
      let value = null;

      const key = filter as keyof CompetitionFilters;

      if (
        key === "onlyForCompetingCompetitions" ||
        key === "onlyForMarkingCompetitions"
      ) {
        return "";
      }

      // For "organisingBody" filter we can get the label from the
      // list of organising body options.
      if (key === "organisingBody" && this.filters[key].value !== null) {
        const index = this.organisingBodyOptions.findIndex(
          (option) => option.value === this.filters[key].value
        );

        if (index !== -1) {
          return this.organisingBodyOptions[index].text;
        }
      }

      // For "event" filter we can get the label from the
      // list of event options.
      if (key === "event" && this.filters[key].value !== null) {
        const index = this.eventOptions.findIndex(
          (option) => option.value === this.filters[key].value
        );

        if (index !== -1) {
          return this.eventOptions[index].text;
        }
      }

      if (
        Object.prototype.hasOwnProperty.call(this.filters, key) &&
        this.filters[key].value !== null
      ) {
        value = this.filters[key].value;
      }

      return value;
    },

    /**
     * Update the sort and reload the competitions.
     *
     * @param {string} sort
     */
    sortBy(sort: string): void {
      this.currentSort = sort;
      this.loadCompetitions();
    },

    /**
     * Handle the "click" event when clicking on a competition.
     *
     * @param {CompetitionModel} competition
     */
    onCompetitionClick(competition: CompetitionModel): void {
      this.$emit("competition-selected", competition);
    },

    /**
     * Handle the "click" to open the filter dialog.
     */
    onFilter(): void {
      this.showFilterDialog = true;
    },

    /**
     * Returns a simple checksum for the current filters.
     * This is mainly used in checking if the filters have been altered.
     *
     * @return {string}
     * @private
     */
    _createFilterChecksum(): string {
      let checksum = "";

      for (const filter in this.filters) {
        if (Object.prototype.hasOwnProperty.call(this.filters, filter)) {
          const value = this.filters[filter as keyof CompetitionFilters].value;

          checksum += `${filter}:${value},`;
        }
      }

      return checksum.slice(0, -1);
    },
  },
});
