









































































import Vue, { PropOptions, PropType, VueConstructor } from "vue";
import { EndModel } from "@memberpoint/ba-result-components";
import MatchStateAlert from "@/components/MatchStateAlert/index.vue";
import MatchDetailsMixin from "@/Mixins/MatchDetailsMixin";
import { CompetitionScoringManager } from "@/services/CompetitionScoringManager";
import MatchResult from "@/Classes/MatchResult";
import { ScoringOptions } from "@/types";
import MatchExtModel from "@/Classes/MatchExtModel";
import MatchBlock from "@/components/MatchBlock/index.vue";

export default (Vue as VueConstructor<
  Vue & InstanceType<typeof MatchDetailsMixin>
>).extend({
  name: "StandardResultOverview",

  components: { MatchBlock, MatchStateAlert },

  mixins: [MatchDetailsMixin],

  props: {
    match: {
      type: MatchExtModel,
      required: true,
    },

    scoringOptions: {
      type: Object as PropType<ScoringOptions>,
      required: false,
      default() {
        return {};
      },
    } as PropOptions<ScoringOptions>,
  },

  data() {
    return {
      ends: [] as Array<EndModel>,
      loadingEnds: false,
      loadingEndsErrors: [] as Array<string>,
      matchData: this.match,
    };
  },

  created(): void {
    if (this.scoringOptions.hasEndScoring) {
      this.loadEnds();
    }
  },

  methods: {
    /**
     * Load the ends for the match.
     */
    loadEnds(): void {
      if (this.loadingEnds) {
        return;
      }

      const manager = new CompetitionScoringManager();

      this.loadingEnds = true;
      this.loadingEndsErrors = [];

      const result = this.match.result as MatchResult;

      manager
        .getEndsForOwner(result.handle)
        .then((ends: EndModel[]) => {
          this.ends = ends;
        })
        .catch((error) => {
          this.loadingEndsErrors = CompetitionScoringManager.getErrorMessagesFromResponse(
            error
          );
        })
        .finally(() => {
          this.loadingEnds = false;
        });
    },
  },
});
